import cn from 'classnames';
import * as React from 'react';
import { PropsWithChildren } from 'react';

import s from './FormRowField.scss';

interface IFormRowFieldProps {
    className?: string;
}

export const FormRowField = (props: PropsWithChildren<IFormRowFieldProps>) => {
    const {
        className,
        children,
    } = props;

    return (
        <div className={cn(s.root, className)}>
            {children}
        </div>
    );
};
