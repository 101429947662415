import { Button, EComponentColor, Loader, useClickOutside } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconCloseLine, IconPlusLine } from '@assets/images/svg';
import { ITag } from '@typings';

import { useTagSelect } from './hooks/TagSelect';
import s from './TagSelect.scss';

export interface TagSelectMethods {
    reset: () => void;
    focus: () => void;
    set: (value: string) => void;
}

interface TagSelectProps {
    className?: string;
    tagClassName?: string;
    color?: EComponentColor;
    debounceTime?: number;
    defaultValue?: ITag[];
    methodsRef?: React.MutableRefObject<TagSelectMethods | null>;
    invalidSymbols?: RegExp;
    maxLength?: number;
    maxWidth?: number;
    minWidth?: number;
    fullWidth?: boolean;
    placeholder?: string;
    readonly?: boolean;
    onTagsChanged?: (tags?: ITag[]) => void;
}

export const TagSelect = (props: TagSelectProps) => {
    const {
        className,
        tagClassName,
        color,
        methodsRef,
        maxWidth,
        minWidth,
        fullWidth,
        placeholder,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const {
        currentTags,
        isLoadInProgress,
        searchResults,
        value,
        onChange,
        onKeyDown,
        onSelectTag,
        onPlusClick,
        onDeleteTagClick,
        set,
        reset,
    } = useTagSelect({ ...props });

    const { t } = useTranslation();

    const tagSelectorRef = useRef<HTMLDivElement>(null);

    const styles = {
        minWidth,
        maxWidth,
    };

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const onTagClick = (tag: ITag) => {
        setTimeout(() => {
            onSelectTag(tag);
        }, 0);
        reset();
    };

    const textFieldClassNames = cn(
        s.textField,
        className,
        s['color-' + color],
        {
            [s.textFieldFullWidth]: fullWidth,
        },
    );

    if (methodsRef) {
        methodsRef.current = {
            reset,
            focus,
            set,
        };
    }

    const getTagItem = (tag: ITag, onSelect?: (tag: ITag) => void) => {
        return (
            <div
                key={tag.id}
                onMouseUp={() => onSelect ? onSelect(tag) : noop}
                className={cn(s.searchTagItem, { [s.searchTagItemSelectable]: !!onSelect })}>
                <div className={s.searchTagItemText}>#{tag.name}</div>
                <div className={cn(s.searchTagItemUsageCount, { [s.searchTagItemUsageCountHidden]: tag.usageCount === 0 })}>
                    {tag.usageCount}
                </div>
            </div>
        );
    };

    useClickOutside({ controlledElementRef: tagSelectorRef, handler: () => reset() });

    return (
        <div className={cn(s.root, { [s.rootFullWidth]: fullWidth })} ref={tagSelectorRef}>
            <div className={s.line}>
                <div className={textFieldClassNames} style={styles}>
                    <div className={s.textFieldInputContainer}>
                        <input
                            className={s.textFieldInput}
                            placeholder={placeholder}
                            ref={inputRef}
                            value={value}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                        />
                    </div>
                    <div className={s.textFieldActionsContainer}>
                        <div className={s.textFieldAction}>
                            <Loader className={
                                cn(
                                    s.loader,
                                    {
                                        [s.loaderHidden]: !isLoadInProgress,
                                    },
                                )}
                            />
                        </div>
                    </div>

                    {(searchResults && searchResults?.length > 0) &&
                        <div className={s.textFieldDropdown}>
                            { searchResults?.map((res) => getTagItem(res, onTagClick)) }
                        </div>
                    }
                </div>
                <Button
                    caption={t('common.add')}
                    className={cn(s.addButton, { [s.addButtonActive]: value.length })}
                    icon={<IconPlusLine/>}
                    disabled={!value.length}
                    margin={{ left: 10 }}
                    padding={{ left: 7, right: 7 }}
                    onClick={onPlusClick}
                />
            </div>

            {currentTags && currentTags.length > 0 &&
                <div className={s.tag}>
                    {currentTags.map((tag) => (
                        <div className={cn(tagClassName, s.tagItem)} key={tag.name}>
                            <div className={s.tagItemText}>{tag.name}</div>
                            <div className={s.tagItemDeleteButton} onClick={() => onDeleteTagClick(tag.name)}><IconCloseLine/></div>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};
