import { useLogger } from '@hyperclap/ui';
import { useEffect, useState } from 'react';

import { OBS_TOKEN_NAME } from '@common';
import { useApi, useAuth } from '@hooks';
import { EAuthMode } from '@typings';

export const useCurrentUser = () => {
    const logger = useLogger({ target: 'useCurrentUser', showTimestamp: true });

    const { authToken, clearTokens, authMode } = useAuth();

    const {
        users: {
            useCurrentUserQuery,
        },
        auth: {
            useLogoutMutation,
            useLazyFetchObsTokenQuery,
        },
    } = useApi();

    const {
        data: currentUser,
        isLoading,
        isFetching,
        isError,
        isSuccess,
        error,
        refetch: refetchCurrentUser,
    } = useCurrentUserQuery(void(0), { skip: authMode === EAuthMode.DEFAULT && !authToken });

    const [logoutUser] = useLogoutMutation();

    const [reissueObsTokenRequest] = useLazyFetchObsTokenQuery();

    const [noCurrentUser, setNoCurrentUser] = useState(false);

    // TODO: Move the method below to the authorization context
    const reissueObsToken = async () => {
        const result = await reissueObsTokenRequest().unwrap();

        if (result?.token) {
            localStorage.setItem(OBS_TOKEN_NAME, result.token);
        }
    };

    const logout = async () => {
        await logoutUser();
        logger.debug(`User logged out, current tokens will be removed`);
        clearTokens();
    };

    useEffect(() => {
        setNoCurrentUser((!isLoading && !isFetching && isSuccess && !currentUser) || isError);

        if (isError && error && (error as { status: number }).status === 403) {
            logger.debug(`Current token is invalid and will be removed`);
            clearTokens();
        }
    }, [currentUser, isLoading, isFetching, isError, isSuccess]);

    useEffect(() => {
        if (!authToken) {
            setNoCurrentUser(true);
        }
    }, []);

    return {
        currentUser,
        noCurrentUser,
        state: {
            isLoading: isLoading || isFetching,
            isError,
            isSuccess,
        },
        refetchCurrentUser,
        reissueObsToken,
        logout,
    };
};
