// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormRowField__root--Tsgyv {
  margin-right: 15px;
}
.FormRowField__root--Tsgyv:last-child {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Elements/components/FormSwitcher/components/FormRowField/FormRowField.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,eAAA;AACR","sourcesContent":[".root {\r\n    margin-right: 15px;\r\n\r\n    &:last-child {\r\n        margin-right: 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FormRowField__root--Tsgyv`
};
export default ___CSS_LOADER_EXPORT___;
