// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.General__root--yOLht {
  flex: 1;
}

.General__column--ZZnSJ {
  margin-right: 16px;
}
.General__column--ZZnSJ:last-child {
  margin-right: 0;
}
.General__columnTitle--nt3dP {
  padding: 0;
  margin-bottom: 10px;
}

.General__size--lNuoW {
  width: 75px;
}
.General__sizeControlInput--FKFbX {
  min-width: 20px;
}

.General__duration--NoYmH {
  width: 80px;
}
.General__durationControlInput--Y254X {
  min-width: 20px;
}

.General__margin--Wrric {
  width: 80px;
}
.General__marginControlInput--_c3Mb {
  min-width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/General/General.scss"],"names":[],"mappings":"AAAA;EACI,OAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;AACI;EACI,eAAA;AACR;AAEI;EACI,UAAA;EACA,mBAAA;AAAR;;AAIA;EACI,WAAA;AADJ;AAGI;EACI,eAAA;AADR;;AAKA;EACI,WAAA;AAFJ;AAII;EACI,eAAA;AAFR;;AAMA;EACI,WAAA;AAHJ;AAKI;EACI,eAAA;AAHR","sourcesContent":[".root {\r\n    flex: 1;\r\n}\r\n\r\n.column {\r\n    margin-right: 16px;\r\n\r\n    &:last-child {\r\n        margin-right: 0;\r\n    }\r\n\r\n    &Title {\r\n        padding: 0;\r\n        margin-bottom: 10px;\r\n    }\r\n}\r\n\r\n.size {\r\n    width: 75px;\r\n\r\n    &ControlInput {\r\n        min-width: 20px;\r\n    }\r\n}\r\n\r\n.duration {\r\n    width: 80px;\r\n\r\n    &ControlInput {\r\n        min-width: 20px;\r\n    }\r\n}\r\n\r\n.margin {\r\n    width: 80px;\r\n\r\n    &ControlInput {\r\n        min-width: 20px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `General__root--yOLht`,
	"column": `General__column--ZZnSJ`,
	"columnTitle": `General__columnTitle--nt3dP`,
	"size": `General__size--lNuoW`,
	"sizeControlInput": `General__sizeControlInput--FKFbX`,
	"duration": `General__duration--NoYmH`,
	"durationControlInput": `General__durationControlInput--Y254X`,
	"margin": `General__margin--Wrric`,
	"marginControlInput": `General__marginControlInput--_c3Mb`
};
export default ___CSS_LOADER_EXPORT___;
