import * as React from 'react';

import {
    LayoutForm,
    StreamojiForm,
    TitleForm,
    TextForm,
    TextBoxForm,
} from './components/forms';
import { EElementMenuItem } from '../../../../enums';
import { useAlertsSettingsType } from '../../../../hooks';


interface IFormSwitcherProps {
    activeMenuItem: EElementMenuItem;
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

export const FormSwitcher = (props: IFormSwitcherProps) => {
    const {
        activeMenuItem,
        alertsSettingsType,
    } = props;

    return (
        <>
            {activeMenuItem === EElementMenuItem.LAYOUT && (
                <LayoutForm alertsSettingsType={alertsSettingsType}/>
            )}
            {activeMenuItem === EElementMenuItem.STREAMOJI && (
                <StreamojiForm alertsSettingsType={alertsSettingsType}/>
            )}
            {activeMenuItem === EElementMenuItem.TITLE && (
                <TitleForm alertsSettingsType={alertsSettingsType}/>
            )}
            {activeMenuItem === EElementMenuItem.TEXT && (
                <TextForm alertsSettingsType={alertsSettingsType}/>
            )}
            {activeMenuItem === EElementMenuItem.TEXT_BOX && (
                <TextBoxForm alertsSettingsType={alertsSettingsType}/>
            )}
        </>
    );
};
