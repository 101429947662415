import { Button, ERowAlignment, ERowJustification, Row, Select } from '@hyperclap/ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import s from './Preset.scss';
import { Field } from '../../components/Elements/components/FormSwitcher/components/Field';

export const Preset = () => {
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.${id}`);
    const tc = (id: string) => translation(`common.${id}`);

    return (
        <Row
            className={s.root}
            justification={ERowJustification.SPACE_BETWEEN}
            alignment={ERowAlignment.BOTTOM}
        >
            <Row alignment={ERowAlignment.BOTTOM}>
                <Field
                    label={t('forms.style')}
                    className={s.select}
                    content={(
                        <Select
                            placeholder={t('notSelected')}
                            options={[]}
                        />
                    )}
                />

                <Button
                    className={s.button}
                    caption={tc('save')}
                />
            </Row>

            <Row>
                <Button
                    className={s.button}
                    caption={tc('saveAs')}
                />
            </Row>
        </Row>
    );
};
