import { Button, EComponentColor, Row } from '@hyperclap/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ISticker } from '@typings';

import s from './ControlPopupDelete.scss';
import { useControlPopupDelete } from './hooks';
import { ControlPopup } from '../ControlPopup';


interface IControlPopupDeleteProps {
    sticker: ISticker;
    onClose(): void;
}

export const ControlPopupDelete = (props: IControlPopupDeleteProps) => {
    const {
        sticker,
        onClose,
    } = props;
    const {
        onDelete,
    } = useControlPopupDelete({
        sticker,
    });
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.controls.popups.delete.${id}`);
    const tc = (id: string) => translation(`common.${id}`);

    const handleCloseClick = () => onClose();

    const handleDeleteClick = async () => {
        await onDelete();

        onClose();
    };

    return (
        <ControlPopup
            className={s.root}
            onClose={onClose}
        >
            <Row className={s.title}>{t('title')}</Row>

            <Row>
                <Button
                    caption={tc('cancel')}
                    color={EComponentColor.ACCENT}
                    onClick={handleCloseClick}
                />
                <Button
                    caption={tc('delete')}
                    linkStyle={true}
                    onClick={handleDeleteClick}
                />
            </Row>
        </ControlPopup>
    );
};
