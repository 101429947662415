import { ERowAlignment, ISelectOption, Row } from '@hyperclap/ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { StrokeSize, TextBoxType } from '@typings';

import {
    IconStyleBeveled,
    IconStyleInclined,
    IconStyleRegular,
    IconStyleRounded,
} from './assets';
import s from './TextBoxForm.scss';
import { EOpacity } from '../../../../../../../enums';
import { useAlertsSettingsType } from '../../../../../../../hooks';
import { IconRadioGroup } from '../../../../../../IconRadioGroup';
import { Field } from '../../Field';
import { FieldColorPicker } from '../../FieldColorPicker';
import { FieldEffects } from '../../FieldEffects';
import { FieldSelect } from '../../FieldSelect';
import { FieldStroke } from '../../FieldStroke';
import { FieldSwitcher } from '../../FieldSwitcher';
import { FormRow } from '../../FormRow';
import { FormRowField } from '../../FormRowField';


interface ITextBoxFormProps {
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

export const TextBoxForm = (props: ITextBoxFormProps) => {
    const {
        alertsSettingsType: {
            alertStyle: {
                alertStyle: {
                    textbox: {
                        enabled,
                        type,
                        opacity,
                        fill,
                        stroke,
                        effects,
                    },
                },
            },
            strokeSizeList,
            opacityList,
            visualEffectInList,
            visualEffectOutList,
            visualEffectSpeedList,
            onTextBoxEnabledChange,
            onTextBoxTypeChange,
            onTextBoxOpacityChange,
            onTextBoxFillChange,
            onTextBoxStrokeSizeChange,
            onTextBoxStrokeFillChange,
            onTextBoxEffectsChange,
        },
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.forms.${id}`);

    const handleOpacityChange = (selection?: ISelectOption) => {
        onTextBoxOpacityChange(selection?.value as EOpacity);
    };

    const handleStrokeSizeChange = (selection?: ISelectOption) => {
        onTextBoxStrokeSizeChange(selection?.value as StrokeSize);
    };

    return (
        <div>
            <FormRow>
                <FieldSwitcher
                    label={t('display')}
                    checked={enabled}
                    onChange={onTextBoxEnabledChange}
                />
            </FormRow>

            <FormRow>
                <Field
                    label={t('position')}
                    content={
                        <IconRadioGroup
                            classNames={{
                                item: s.styleItem,
                            }}
                            items={[
                                {
                                    value: TextBoxType.REGULAR,
                                    title: t('textBoxType.regular'),
                                    icon: IconStyleRegular,
                                },
                                {
                                    value: TextBoxType.ROUNDED,
                                    title: t('textBoxType.rounded'),
                                    icon: IconStyleRounded,
                                },
                                {
                                    value: TextBoxType.BEVELED,
                                    title: t('textBoxType.beveled'),
                                    icon: IconStyleBeveled,
                                },
                                {
                                    value: TextBoxType.INCLINED,
                                    title: t('textBoxType.inclined'),
                                    icon: IconStyleInclined,
                                },
                            ]}
                            value={type}
                            onChange={onTextBoxTypeChange}
                        />
                    }
                />
            </FormRow>

            <FormRow>
                <FormRowField>
                    <Row alignment={ERowAlignment.BOTTOM}>
                        <FieldSelect
                            className={s.opacitySelect}
                            classNameControlInput={s.opacitySelectControlInput}
                            label={t('opacity')}
                            options={opacityList.map((item) => ({
                                caption: item.title,
                                value: item.value,
                                selected: opacity === Number(item.value),
                            }))}
                            onSelectionChanged={handleOpacityChange}
                        />

                        <FieldColorPicker
                            value={fill}
                            onChange={onTextBoxFillChange}
                        />
                    </Row>
                </FormRowField>

                <FormRowField>
                    <FieldStroke
                        className={s.stroke}
                        classNameSelect={s.strokeSelect}
                        classNameControlInput={s.strokeSelectControlInput}
                        options={strokeSizeList.map((item) => ({
                            caption: item.title,
                            value: item.value,
                            selected: stroke.size === item.value,
                        }))}
                        fill={stroke.fill}
                        onSelectionChanged={handleStrokeSizeChange}
                        onFillChange={onTextBoxStrokeFillChange}
                    />
                </FormRowField>
            </FormRow>

            <FormRow>
                <FieldEffects
                    visualEffectInList={visualEffectInList}
                    visualEffectOutList={visualEffectOutList}
                    visualEffectSpeedList={visualEffectSpeedList}
                    value={effects}
                    onChange={onTextBoxEffectsChange}
                />
            </FormRow>
        </div>
    );
};
