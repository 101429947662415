import { EtmaRating, ITag } from './Stickers';
import { BackgroundMode, IMorpherDeclensions, IPosition } from '../common';

export interface IObsOverlayParams {
    showOverlay: boolean;
    showCorners: boolean;
    showGrid: boolean;
    showStickingZone: boolean;
}

export interface IChannel {
    stickerSize: string; // TODO: Rename to stickerSize
    stickerStickAreasMask: number; // TODO: Rename to stickerStickAreasMask
    antiSwearBadWords: string;
    antiSwearRemoveLinks: boolean;
    avatarUrl: string;
    backgroundMode: BackgroundMode;
    backgroundUrl: string;
    catalogueStickerPrice: number;
    currencyImageUrl: string;
    currencyName: string;
    currencyNameDeclensions: IMorpherDeclensions;
    disableStickers: boolean;
    discordLink: string;
    donationAlertsUserId: number;
    donationAlertsUsername: string;
    etmaRating: EtmaRating;
    forbiddenTags: ITag[];
    forbiddenContentMarks: string[];
    fullscreenStickersEnabled: boolean;
    fullscreenStickersPrice: number;
    isAntiSwearEnabled: boolean;
    isBonusAlertEnabled: boolean;
    isCopyrightFreeOnly: boolean;
    isNewSupporterAlertEnable: boolean;
    isPopupNewSaleEnabled: boolean;
    isPopupNickNameEnabled: boolean;
    isSoundOnlyStickersEnabled: boolean;
    isStickersMessagesEnabled: boolean;
    isThematicSetsEnabled: boolean;
    link: string;
    masteredObsSettings: boolean;
    memeCannonEnabled: boolean;
    memeCannonPrice: number;
    memePartyActive: boolean;
    memePartyConfetti: boolean;
    memePartyDuration: number;
    memePartyGoal: number;
    memePartyOwnSoundEnabled: boolean;
    memePartyOwnSoundUrl: string;
    memePartyProgressPosition: IPosition;
    memePartySendDelay: number;
    memePartySoundEnabled: boolean;
    name: string;
    newbieActionEnabled: boolean;
    newbieAction: number;
    onlyChannelStickers: boolean;
    onlyTwitchFriendly: boolean;
    paywallText: string;
    popupNickNameAreaMask: number;
    refCode: string;
    sendDelay: number;
    soundVolume: number;
    supporterName: string;
    supporterNameDeclensions: IMorpherDeclensions;
    telegramLink: string;
    twitchLink: string;
    twitterLink: string;
    youtubeLink: string;
    welcomeBonus: number;
    welcomeBonusEnabled: boolean;
}
