import { useState } from 'react';

import { DEFAULT_SOUND_VOLUME, DEFAULT_STICKER_PRICE } from '@common';
import { useStickers } from '@hooks/app/stickers';
import { ISticker, IUser } from '@typings';

interface IUseControlPopupSettingsParams {
    sticker: ISticker;
    currentUser?: IUser;
    updateSticker(stickerData: ISticker): void;
}

export const useControlPopupSettings = (params: IUseControlPopupSettingsParams) => {
    const {
        sticker,
        currentUser,
        updateSticker,
    } = params;
    const {
        changeStickerCustomSettings,
    } = useStickers({ streamerChannelName: currentUser?.channel?.name });
    const initialStickerPrice = sticker.customSettings?.customPrice || currentUser?.channel.catalogueStickerPrice || DEFAULT_STICKER_PRICE;
    const initialStickerVolume = sticker.customSettings?.customVolume || currentUser?.channel.soundVolume || DEFAULT_SOUND_VOLUME;
    const [price, setPrice] = useState<number | undefined>(initialStickerPrice);
    const [volume, setVolume] = useState<number | undefined>(initialStickerVolume);

    const onPriceChange = (value: string) => {
        if (value && !isNaN(Number(value))) {
            setPrice(Number(value));
        }
    };

    const onVolumeChange = (value: number) => {
        if (volume !== value) {
            setVolume(value);
        }
    };

    const onSubmit = async () => {
        const stickerCustomSettings = {
            ...sticker.customSettings,
            ...(
                price !== sticker.customSettings?.customPrice
                    ? { customPrice: price }
                    : {}
            ),
            customVolume: volume,
        };

        delete stickerCustomSettings._id;

        const updatedSticker = await changeStickerCustomSettings(
            sticker,
            stickerCustomSettings,
        );

        updateSticker(updatedSticker);
    };

    return {
        price,
        volume,
        onPriceChange,
        onVolumeChange,
        onSubmit,
    };
};
