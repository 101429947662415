import { getHumanReadableNumber } from '@hyperclap/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconSendMessageSolid } from '@assets/images/svg';
import { ISticker } from '@typings';

import s from './SentCounter.scss';

interface ISentCounterProps {
    sticker: ISticker;
}

export const SentCounter = (props: ISentCounterProps) => {
    const { sticker } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.sent-counter.${id}`);

    return (
        <div className={s.root}>
            <IconSendMessageSolid className={s.icon}/>
            <div className={s.count}>
                {sticker.usageCount
                    ? getHumanReadableNumber(sticker.usageCount)
                    : 0
                }
            </div>
            {t('sent')}
        </div>
    );
};
