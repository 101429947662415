// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextBoxForm__styleItem--rbJZ3 {
  width: 90px;
}

.TextBoxForm__opacity--ZiupU,
.TextBoxForm__stroke--KepqE {
  margin-right: 10px;
}
.TextBoxForm__opacitySelect--i15pZ,
.TextBoxForm__strokeSelect--gn80Q {
  width: 100px;
  margin-right: 5px;
}
.TextBoxForm__opacitySelectControlInput--Cacjv,
.TextBoxForm__strokeSelectControlInput--IkBOj {
  min-width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Elements/components/FormSwitcher/components/forms/TextBoxForm/TextBoxForm.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;;AAIA;;EAEI,kBAAA;AADJ;AAGI;;EACI,YAAA;EACA,iBAAA;AAAR;AAEQ;;EACI,eAAA;AACZ","sourcesContent":[".style {\r\n    &Item {\r\n        width: 90px;\r\n    }\r\n}\r\n\r\n.opacity,\r\n.stroke {\r\n    margin-right: 10px;\r\n\r\n    &Select {\r\n        width: 100px;\r\n        margin-right: 5px;\r\n\r\n        &ControlInput {\r\n            min-width: 20px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styleItem": `TextBoxForm__styleItem--rbJZ3`,
	"opacity": `TextBoxForm__opacity--ZiupU`,
	"stroke": `TextBoxForm__stroke--KepqE`,
	"opacitySelect": `TextBoxForm__opacitySelect--i15pZ`,
	"strokeSelect": `TextBoxForm__strokeSelect--gn80Q`,
	"opacitySelectControlInput": `TextBoxForm__opacitySelectControlInput--Cacjv`,
	"strokeSelectControlInput": `TextBoxForm__strokeSelectControlInput--IkBOj`
};
export default ___CSS_LOADER_EXPORT___;
