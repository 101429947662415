import { ISticker } from '@typings';

interface IUseControlPopupReportParams {
    sticker: ISticker;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useControlPopupReport = (params: IUseControlPopupReportParams) => {
    return {

    };
};
