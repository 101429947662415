import { Column, Divider, EComponentColor, ERowAlignment, ERowJustification, Row, Switch } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconEditLine, IconPlayLine } from '@assets/images/svg';
import { AlertType, IAlertTypeItem } from '@typings';

import s from './AlertTypeItem.scss';
import { useAlertTypeItem } from './hooks';


interface IAlertTypeItemProps {
    type: AlertType;
    item?: IAlertTypeItem;
    onActiveChange(value: boolean): void;
}

export const AlertTypeItem = (props: IAlertTypeItemProps) => {
    const {
        type,
        item,
        onActiveChange,
    } = props;
    const {
        isActive,
        handleActiveChange,
    } = useAlertTypeItem({
        type,
        item,
        onActiveChange,
    });
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts.${id}`);

    const navigate = useNavigate();

    const goToEdit = () => navigate(`/channel/alerts/${type}`);

    const handleClick = () => goToEdit();

    const handleStateClick = (event: React.MouseEvent) => {
        event.stopPropagation();

        handleActiveChange(!isActive);
    };

    const handleEditClick = (event: React.MouseEvent) => {
        event.stopPropagation();

        goToEdit();
    };

    const handleStateSwitchClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <Column
            className={s.root}
            onClick={handleClick}
        >
            <Row
                className={s.content}
                alignment={ERowAlignment.CENTER}
                justification={ERowJustification.SPACE_BETWEEN}
            >
                <div className={s.title}>{t(`types.${type}`)}</div>

                <Row alignment={ERowAlignment.CENTER}>
                    <div
                        className={cn(s.state, { [s.stateActive]: isActive })}
                        onClick={handleStateClick}
                    >
                        <Switch
                            className={s.stateSwitch}
                            color={EComponentColor.ACCENT}
                            checked={isActive}
                            onChange={handleActiveChange}
                            onClick={handleStateSwitchClick}
                        />

                        <div className={s.stateText}>
                            {t('active')}
                        </div>
                    </div>

                    <div className={s.control}>
                        <IconPlayLine className={s.controlIcon}/>
                    </div>

                    <div
                        className={s.control}
                        onClick={handleEditClick}
                    >
                        <IconEditLine className={s.controlIcon}/>
                    </div>
                </Row>
            </Row>

            <Divider className={s.divider}/>
        </Column>
    );
};
