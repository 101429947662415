import {
    Button,
    Column,
    EColumnAlignment,
    EColumnJustification,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    ERowJustification,
    Icon,
    Label,
    Row,
    TransparentButton,
    useModals,
} from '@hyperclap/ui';
import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconArrowLeftLine, IconBagAlt, IconMagic, IconPauseLine, IconPlayLine } from '@assets/images/svg';
import { TApp } from '@hooks';

// import { IAvatarAssetFaceEmotion } from '@typings';
import s from './AvatarEditor.scss';
import { AssetList } from './components/AssetList';
import { AssetsControl } from './components/AssetsControl';
import { CategoryList } from './components/CategoryList';
import { CategoryTabs } from './components/CategoryTabs';
import { ColorSelector } from './components/ColorSelector';
import { FaceList } from './components/FaceList';
import { EPreviewSize, Preview } from './components/Preview';
import { VoicesList } from './components/VoicesList';
import { useAvatarEditor, useVoices } from './hooks';
import { PersonalConfig } from '../PersonalConfig';
import { PersonalInfo } from '../PersonalInfo';

interface IAvatarEditorProps extends TApp {}

export const AvatarEditor = (props: IAvatarEditorProps) => {
    const {
        currentUser: {
            currentUser,
        },
        settings: {
            personal: {
                updateName,
            },
        },
    } = props;
    const {
        isLoading,
        assets,
        assetsFaces,
        assetsFacesEmotionsCategories,
        assetsCategories,
        displayedAssets,
        displayedFace,
        displayedFaceEmotion,
        assetsLayerPositions,
        hiddenLayerPositions,
        selectedCategory,
        filteredAssets,
        assetsSkinColors,
        assetsEyeColors,
        bodyColor,
        eyeColor,
        isFaceListDisplayed,
        variableColorsMap,
        categoriesTabs,
        isVoiceSelectionMode,
        onCategorySelect,
        onAssetSelect,
        onUnAssetSelect,
        onFaceSelect,
        onFaceUnSelect,
        // onFaceEmotionSelect,
        onBodyColorChange,
        onEyeColorChange,
        // onDisplayedAssetsChange,
        onAssetsControlChangeAssetsList,
        onCategoryFaceSelect,
        onBackClick,
        onVariableColorsMapSelectColor,
        onRandomizeClick,
        onNameChange,
        onSaveClick,
        onSelectVoiceClick,
    } = useAvatarEditor({
        currentUser,
        updateName,
    });
    const { t: translation } = useTranslation();
    const { showModal } = useModals();
    const { playVoice, stopVoicePlaying, playedVoiceId, TEST_PHRASE } = useVoices();

    const player = useRef<HTMLAudioElement>(null);

    const t = (id: string) => translation(`avatar-editor.${id}`);

    const tc = (id: string) => translation(`common.${id}`);

    const handleBackClick = () => onBackClick();

    const handleControlClick = () => {
        showModal(
            <AssetsControl
                selectedAssets={displayedAssets}
                assets={assets}
                assetsFaces={assetsFaces}
                assetsCategories={assetsCategories}
                layerPositions={assetsLayerPositions}
                onChangeAssetsList={onAssetsControlChangeAssetsList}
            />,
        );
    };

    const handlePlayVoiceClick = () => {
        if (currentUser?.voice) {
            if (playedVoiceId) {
                stopVoicePlaying(player);
            } else {
                playVoice(currentUser.voice, TEST_PHRASE, player);
            }
        }
    };

    return (
        <>
            {!isLoading && (
                <div className={s.root}>
                    <Row alignment={ERowAlignment.TOP}>
                        <Column
                            className={cn(s.column, s.columnPreview)}
                            alignment={EColumnAlignment.STRETCH}
                        >
                            <Row className={s.backWrapper}>
                                <TransparentButton
                                    className={cn(
                                        s.back,
                                        {
                                            [s.backVisible]: selectedCategory || isFaceListDisplayed ||
                                                isVoiceSelectionMode,
                                        },
                                    )}
                                    caption={(
                                        <>
                                            <IconArrowLeftLine className={s.backIcon}/>
                                            {tc('back')}
                                        </>
                                    )}
                                    onClick={handleBackClick}
                                />
                            </Row>

                            <Preview
                                className={s.preview}
                                assets={assets}
                                displayedAssets={displayedAssets}
                                assetsCategories={assetsCategories}
                                face={displayedFace}
                                faceEmotion={displayedFaceEmotion}
                                layerPositions={assetsLayerPositions}
                                size={EPreviewSize.L}
                                hiddenLayerPositions={hiddenLayerPositions}
                                isScale={true}
                                skinColor={bodyColor}
                                testEyeColor={eyeColor}
                                variableColorsMap={variableColorsMap}
                            />

                            <Column
                                grow
                                padding={0} margin={{ bottom: 30 }}
                                justification={EColumnJustification.STRETCH}
                                className={s.voice}
                            >
                                <audio ref={player}/>
                                <Row>
                                    <Label caption={tc('voice')} size={EComponentSize.SMALL} muted/>
                                </Row>
                                <Row justification={ERowJustification.SPACE_BETWEEN}>
                                    <Row columnGap={10} className={s.voiceControls}>
                                        { !!currentUser?.voice &&
                                            <Icon
                                                icon={playedVoiceId ? <IconPauseLine/> : <IconPlayLine/>}
                                                size={EComponentSize.EXTRA_SMALL}
                                                onClick={handlePlayVoiceClick}
                                                className={s.playIcon}
                                            />
                                        }
                                        <Label
                                            caption={currentUser?.voice?.name ?? t('select-voice')}
                                            size={EComponentSize.MEDIUM}
                                            className={s.voiceName}
                                            onClick={handlePlayVoiceClick}
                                        />
                                        <Column grow/>
                                    </Row>
                                    <Button
                                        caption={tc('select')}
                                        className={s.selectVoiceBtn}
                                        onClick={onSelectVoiceClick}
                                    />
                                </Row>
                            </Column>

                            <div className={s.controls}>
                                <Button
                                    className={s.controlsSave}
                                    caption={tc('save')}
                                    color={EComponentColor.ACCENT}
                                    onClick={onSaveClick}
                                />

                                <div className={s.controlsIcons}>
                                    <div
                                        className={s.controlsIconsItem}
                                        onClick={handleControlClick}
                                    >
                                        <IconBagAlt className={s.controlsIconsItemIcon}/>
                                    </div>

                                    <div
                                        className={s.controlsIconsItem}
                                        onClick={onRandomizeClick}
                                    >
                                        <IconMagic className={s.controlsIconsItemIcon}/>
                                    </div>
                                </div>
                            </div>

                            {currentUser && (
                                <PersonalInfo currentUser={currentUser}/>
                            )}
                        </Column>

                        <Column grow className={s.column}>
                            <div className={s.content}>
                                <PersonalConfig
                                    currentUser={currentUser}
                                    onNameChange={onNameChange}
                                />

                                {!isVoiceSelectionMode &&
                                <>
                                    {selectedCategory
                                        ? (
                                            <>
                                                <Row>
                                                    <CategoryTabs
                                                        categories={categoriesTabs}
                                                        selectedCategory={selectedCategory}
                                                        isFaceListDisplayed={isFaceListDisplayed}
                                                        onSelect={onCategorySelect}
                                                        onFaceClick={onCategoryFaceSelect}
                                                    />
                                                </Row>

                                                <Row>
                                                    <AssetList
                                                        assets={assets}
                                                        filteredAssets={filteredAssets}
                                                        displayedAssets={displayedAssets}
                                                        variableColorsMap={variableColorsMap}
                                                        assetsFaces={assetsFaces}
                                                        assetsSkinColors={assetsSkinColors}
                                                        assetsCategories={assetsCategories}
                                                        layerPositions={assetsLayerPositions}
                                                        onSelect={onAssetSelect}
                                                        onUnSelect={onUnAssetSelect}
                                                        onVariableColorsMapSelectColor={onVariableColorsMapSelectColor}
                                                    />
                                                </Row>
                                            </>
                                        )
                                        : (
                                            <>
                                                {isFaceListDisplayed
                                                    ? (
                                                        <>
                                                            <Row>
                                                                <CategoryTabs
                                                                    categories={categoriesTabs}
                                                                    selectedCategory={selectedCategory}
                                                                    isFaceListDisplayed={isFaceListDisplayed}
                                                                    onSelect={onCategorySelect}
                                                                    onFaceClick={onCategoryFaceSelect}
                                                                />
                                                            </Row>

                                                            <Row>
                                                                <FaceList
                                                                    displayedFace={displayedFace}
                                                                    displayedFaceEmotion={displayedFaceEmotion}
                                                                    assetsFaces={assetsFaces}
                                                                    assetsSkinColors={assetsSkinColors}
                                                                    assets={assets}
                                                                    assetsCategories={assetsCategories}
                                                                    layerPositions={assetsLayerPositions}
                                                                    assetsFacesEmotionsCategories={assetsFacesEmotionsCategories}
                                                                    onSelect={onFaceSelect}
                                                                    onUnSelect={onFaceUnSelect}
                                                                />
                                                            </Row>
                                                        </>
                                                    )
                                                    : (
                                                        <CategoryList
                                                            categories={assetsCategories}
                                                            onItemClick={onCategorySelect}
                                                            onFaceClick={onCategoryFaceSelect}
                                                        />
                                                    )
                                                }
                                            </>
                                        )}
                                </>
                                }
                            </div>

                            {!selectedCategory && !isFaceListDisplayed && !isVoiceSelectionMode && (
                                <>
                                    <ColorSelector
                                        title={t('bodyColor')}
                                        colors={assetsSkinColors}
                                        value={bodyColor}
                                        onSelect={onBodyColorChange}
                                    />

                                    <ColorSelector
                                        title={t('eyeColor')}
                                        colors={assetsEyeColors}
                                        value={eyeColor}
                                        onSelect={onEyeColorChange}
                                    />
                                </>
                            )}

                            {isVoiceSelectionMode && currentUser &&
                                <VoicesList />
                            }
                        </Column>
                    </Row>
                </div>
            )}
        </>
    );
};
