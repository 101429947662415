import { Logger } from '@hyperclap/ui';

import { IContentMark } from '@typings';

import { baseApi } from './base.api';

const logger = new Logger({ target: 'ContentMarksApi', showTimestamp: true });
logger.trace(`Configure Content Marks API`);

export const contentMarksApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'ContentMarks_List',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadContentMarks: build.query<IContentMark[], void>({
                query: () => ({
                    url: `content-mark/list`,
                    method: 'GET',
                }),
                providesTags: ['ContentMarks_List'],
            }),
        }),
    });
