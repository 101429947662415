import {
    Button,
    Column,
    Row,
    ERowJustification,
    EComponentColor,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useState } from 'react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ImageLandingLogo, ImageWitnessFromFryazino } from '@assets/images/png';
import { IconChevronLeftLine, IconChevronRightLine } from '@assets/images/svg';
import { ImageMrCrabs } from '@assets/images/webp';
import { TApp } from '@hooks';
import { Footer } from '@memealerts/views';
import { Login, DesktopPage } from '@views';

import s from './Desktop.scss';
import { Header } from '../../components';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { VIDEO_DATA } from '../../const';

/** To remove *any* type. Provides information about properties of the swiper which is used in the code below */
interface ISwiperProxy {
    activeIndex: number;
}

/** Desktop layout of the landing properties */
interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        modals: {
            showModal,
        },
        translation: {
            t,
        },
    } = props;

    const [activeSlideIndex, setActiveSlideIndex] = useState(2);
    const [animationChanged, setAnimationChanged] = useState(false);

    const videos = VIDEO_DATA.map((video, idx) => (
        <video
            key={idx}
            id={s.swiperVideo + idx}
            className={s.swiperVideo}
            src={video.src}
            preload='metadata'
            controls={activeSlideIndex === 0}
        />
    ));

    const onChangeSlideStart = () => setAnimationChanged(true);

    const onChangeSlideEnd = (swiper: ISwiperProxy) => {
        setActiveSlideIndex(swiper.activeIndex);
        const prevVideo = document.getElementById(s.swiperVideo + (swiper.activeIndex - 1)) as HTMLVideoElement;

        if (prevVideo && prevVideo.currentTime > 0 && !prevVideo.paused && !prevVideo.ended) {
            prevVideo.pause();
        }

        const nextVideo = document.getElementById(s.swiperVideo + (swiper.activeIndex + 1)) as HTMLVideoElement;

        if (nextVideo && nextVideo.currentTime > 0 && !nextVideo.paused && !nextVideo.ended) {
            nextVideo.pause();
        }

        setAnimationChanged(false);
    };

    const onDescriptionClick = () => window.open(VIDEO_DATA[activeSlideIndex].externalLink, '_blank');

    const doLogin = (returnUrl?: string) => showModal({
        content: <Login {...props} returnUrl={returnUrl ?? location.pathname}/>,
        options: {
            bodyClassName: s.loginModalBody,
        },
    });

    const header = <Header {...props}/>;
    const footer = <Footer {...props} hideThemeSwitch/>;

    return (
        <DesktopPage header={header} footer={footer}>
            <Column padding={{ top: 20, bottom: 20 }}>

                <Row margin={{ bottom: 20 }} justification={ERowJustification.CENTER}>
                    <Column rowGap={20}>
                        <Row className={s.topBannerHeader}>
                            {t('landing.tagline.spiceUp')}
                        </Row>
                        <Row className={s.topBannerDescription}>
                            {t('landing.tagline.tiredOf')}
                        </Row>
                    </Column>
                    <Column className={s.topBannerImage}>
                        <img className={s.topBannerImageOlyashaa} src={ImageLandingLogo} alt={''}/>
                    </Column>
                </Row>

                <Row className={s.rowTitle} justification={ERowJustification.CENTER}>
                    {t('landing.tagline.newEra')}
                </Row>

                <Row className={s.videoContainer}>
                    <iframe
                        width={656}
                        height={369}
                        src="https://www.youtube.com/embed/58cVTsL1Q98"
                        title={`MemeAlerts: ${t('streamsAndMemes')} = ❤️`}
                        style={{ border: 0 }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                </Row>

                <Row padding={{ top: 40 }} justification={ERowJustification.CENTER}>
                    <Button
                        caption={t('landing.connect')}
                        color={EComponentColor.ACCENT}
                        style={{ width: 250, height: 55, borderRadius: 15 }}
                        onClick={() => doLogin()}
                    />
                </Row>

                <Row columnGap={60} padding={{ top: 70, bottom: 40 }} justification={ERowJustification.CENTER}>
                    <Column rowGap={20}>
                        <Row className={s.topBannerHeader}>
                            {t('landing.tagline.earnMoney')}
                        </Row>
                        <Row className={s.topBannerDescription}>
                            {t('landing.tagline.setPrice')}
                        </Row>
                    </Column>
                    <Column className={s.topBannerImage}>
                        <img alt='' className={s.topBannerImageMrCrabs} src={ImageMrCrabs}/>
                    </Column>
                </Row>

                <Row margin={{ top: 40 }} justification={ERowJustification.CENTER} className={s.rowTitle}>
                    {t('landing.tagline.alreadyInUse')}
                </Row>

                <Row grow justification={ERowJustification.CENTER}>
                    <Row className={s.swiper}>
                        <Swiper
                            width={780}
                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            loop={false}
                            slidesPerView={'auto'}
                            initialSlide={2}
                            coverflowEffect={{
                                slideShadows: false,
                                rotate: 2,
                                stretch: 360,
                                depth: 500,
                                modifier: 1,
                            }}
                            navigation={{
                                nextEl: '.' + s.sliderNext,
                                prevEl: '.' + s.sliderPrev,
                            }}
                            focusableElements={'input, select, option, textarea, button, label'}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                            className={s.swiperContainer}
                            onTransitionStart={onChangeSlideStart}
                            onTransitionEnd={onChangeSlideEnd}
                            simulateTouch={false}
                            edgeSwipeDetection={true}
                        >
                            {videos.map((slide, idx) =>
                                <SwiperSlide key={idx} className={s.swiperContainerSlide}>
                                    {slide}
                                </SwiperSlide>,
                            )}

                            <div className="slider-controler">
                                <div className={s.sliderPrev}>
                                    <IconChevronLeftLine/>
                                </div>
                                <div className={s.sliderNext}>
                                    <IconChevronRightLine/>
                                </div>
                            </div>
                        </Swiper>
                        <div onClick={onDescriptionClick}
                            className={cn(s.swiperDescription, { [s.swiperDescriptionChange]: animationChanged })}>
                            <img alt='' className={s.swiperDescriptionLogo} src={VIDEO_DATA[activeSlideIndex].logo}/>
                            <div className={s.swiperDescriptionInfo}>
                                <div className={s.swiperDescriptionInfoName}>{VIDEO_DATA[activeSlideIndex].name}</div>
                                <div
                                    className={s.swiperDescriptionInfoFollowers}>{VIDEO_DATA[activeSlideIndex].followers}</div>
                            </div>
                        </div>
                        <div className={s.swiperWitnessContainer}>
                            <img alt='' className={s.swiperWitness} src={ImageWitnessFromFryazino}/>
                        </div>
                    </Row>
                </Row>

                <Row margin={{ top: 55, bottom: 65 }} justification={ERowJustification.CENTER}>
                    <Button
                        caption={t('landing.try')}
                        color={EComponentColor.ACCENT}
                        style={{ width: 250, height: 55, borderRadius: 15 }}
                        onClick={() => doLogin()}
                    />
                </Row>
            </Column>
        </DesktopPage>
    );
};
