import { NavigateOptions, useNavigate } from 'react-router-dom';

export const useNavigator = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);
    const goToPath = (path: string, options?: NavigateOptions) => navigate(path, options);

    const goToHome = () => navigate('/');
    const goToChannelSettings = () => goToPath('/channel/page');
    const goToDashboard = () => navigate('/dashboard');
    const goToEvents = (timestamp?: number) => timestamp ? navigate(`/events/${timestamp}`) : navigate('/events');
    const goToSettings = () => navigate('/settings');
    const goToStickerCreation = () => navigate('/sticker/create');
    const goToStickersCatalogue = () => navigate('/stickers');
    const goToSupporter = (supporterId: string) => navigate(`/supporters/${supporterId}`);
    const goToSupporters = () => navigate('/supporters');
    const goToSearch = (query: string) => query ? navigate(`/stickers/search?query=${query}`) : navigate(`/stickers`);

    const goToStreamerPage = (channelLink?: string) => navigate(`/${channelLink ?? ''}`);
    const goToStreamerPageLastSent = (channelLink?: string) => navigate(channelLink ? `/${channelLink}/sent` : '/');
    const goToStreamerPageFavorites = (channelLink?: string) => navigate(channelLink ? `/${channelLink}/favorites` : '/');
    const goToStreamerPageCreated = (channelLink?: string) => navigate(channelLink ? `/${channelLink}/created` : '/');
    const goToStreamerPagePopular = (channelLink?: string) => navigate(channelLink ? `/${channelLink}/popular` : '/');
    const goToStreamerPageChannel = (channelLink?: string) => navigate(channelLink ? `/${channelLink}/channel` : '/');
    const goToStreamerPageThematicSet = (channelLink?: string, setId?: string) => navigate(
        channelLink && setId
            ? `/${channelLink}/thematic/${setId}`
            : '/',
    );

    const gotoStreamerPageSearchResult = (channelLink?: string, query?: string) => {
        if (channelLink) {
            navigate(query ? `/${channelLink}/search?query=${query}` : `/${channelLink}`);
        }
    };

    const openEventsTab = () => window.open('/all-events', '_blank');

    const goToSuspendedPage = () => navigate('/suspended');

    return {
        goBack,

        goToHome,
        goToChannelSettings,
        goToDashboard,
        goToEvents,
        goToPath,
        goToSettings,
        goToStickerCreation,
        goToStickersCatalogue,
        goToSupporter,
        goToSupporters,
        goToSearch,

        goToStreamerPage,
        goToStreamerPageLastSent,
        goToStreamerPageFavorites,
        goToStreamerPageCreated,
        goToStreamerPagePopular,
        goToStreamerPageChannel,
        goToStreamerPageThematicSet,
        gotoStreamerPageSearchResult,
        openEventsTab,

        goToSuspendedPage,
    };
};
