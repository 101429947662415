import { Button, Column, EComponentSize, Icon, Label, Row } from '@hyperclap/ui';
import React, { MouseEvent, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';

import { IconGarbageLine, IconUploadLine, IconVolumeLine } from '@assets/images/svg';
import { IPreparedResult } from '@typings';

import s from './AudioUploader.scss';

const fileTypes = ['MP3', 'WAV'];

interface IAudioUploaderProps {
    file?: File,
    preparedResult: IPreparedResult;
    overrideEmbeddedAudio: boolean;
    handleChange(file: File | undefined): void;
}

export const AudioUploader = (props: IAudioUploaderProps) => {
    const {
        file,
        handleChange,
        preparedResult,
        overrideEmbeddedAudio,
    } = props;

    const { t } = useTranslation();

    const [audioFile, setAudioFile] = useState<File | undefined>(file);

    const handleAudioChange = (file: File) => {
        setAudioFile(file);
        handleChange(file);
    };

    const deleteAudio = (event: MouseEvent) => {
        event.preventDefault();
        setAudioFile(undefined);
        handleChange(undefined);
    };

    const hasAudio = audioFile || (preparedResult.hasAudio && !overrideEmbeddedAudio);

    return (
        <Column grow>
            <FileUploader
                classes={s.fileUploader}
                fileOrFiles={audioFile}
                handleChange={handleAudioChange}
                name="file"
                types={fileTypes}
            >
                <Column>
                    {!hasAudio &&
                        <Button
                            caption={t('sticker-creation.add-sound.add-sound')}
                            icon={<IconVolumeLine/>}
                            className={s.addAudioButton}
                        />
                    }
                    {hasAudio &&
                        <Row columnGap={10}>
                            <Column>
                                <Icon icon={<IconVolumeLine/>} size={EComponentSize.SMALL}/>
                            </Column>
                            <Column grow width={200}>
                                <Label
                                    caption={
                                        preparedResult.hasAudio && !overrideEmbeddedAudio
                                            ? 'Embedded audio'
                                            : audioFile!.name
                                    }
                                    className={s.trackName}
                                />
                            </Column>
                            <Column>
                                <Button icon={<IconUploadLine/>} circle />
                            </Column>
                            <Column>
                                <div onMouseUp={(e) => deleteAudio(e)}>
                                    <Button icon={<IconGarbageLine/>} circle />
                                </div>
                            </Column>
                        </Row>
                    }
                </Column>
            </FileUploader>
        </Column>
    );
};
