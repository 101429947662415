import { IMuteUserRequest, IMuteUserResponse } from '@typings';

import { baseApi } from './base.api';

export const muteApi = baseApi
    .enhanceEndpoints({
        addTagTypes: ['Supporters_CurrentSupporter'],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            muteUser: build.mutation<IMuteUserResponse, IMuteUserRequest>({
                query: (data: IMuteUserRequest) => ({
                    url: 'mute',
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['Supporters_CurrentSupporter'],
            }),
            unmuteUser: build.mutation<void, IMuteUserRequest>({
                query: (data: IMuteUserRequest) => ({
                    url: 'mute',
                    method: 'DELETE',
                    body: data,
                }),
                invalidatesTags: ['Supporters_CurrentSupporter'],
            }),
        }),
    });
