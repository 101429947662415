import React, { useEffect, useState } from 'react';

interface ITimer {
    className?: string;
    time: number;
}

export const Timer = (props: ITimer) => {
    const { className, time } = props;
    const [current, setCurrent] = useState(Math.round(time / 1000));
    const formatted = `00:${current >= 10 ? current : '0' + current}`;

    const tick = (value: number) => {
        if (value > 0) {
            setCurrent(value - 1);

            if (value > 1) {
                setTimeout(() => tick(value - 1), 1000);
            }
        }
    };

    useEffect(() => {
        setTimeout(() => tick(current), 1000);
    }, []);

    return (
        <div className={className}>{formatted}</div>
    );
};
