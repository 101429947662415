export enum AlertType {
    CURRENCY_PURCHASE = 'currency-purchase',
    BONUS = 'bonus',
    NEW_SUPPORTER = 'new-supporter',
    STICKER = 'sticker',
}

export enum AlertLayout {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

export enum AlertPosition {
    TOP_LEFT = 'top-left',
    TOP = 'top',
    TOP_RIGHT = 'top-right',
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM = 'bottom',
    BOTTOM_RIGHT = 'bottom-right',
}

export enum AlertSize {
    XS = 'xs',
    S = 's',
    M = 'm',
    L = 'l',
    XL = 'xl'
}

export enum FillType {
    FLAT = 'flat',
    GRADIENT = 'gradient',
}

export enum FontSize {
    S = 's',
    M = 'm',
    L = 'l',
}

export enum GraphicsPosition {
    BEHIND = 'behind',
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
}

export enum StreamojiPosition {
    LEFT = 'left',
    RIGHT = 'right',
}

export enum StreamojiSize {
    XS = 'xs',
    S = 's',
    M = 'm',
    L = 'l',
}

export enum StreamojiType {
    TRANSPARENT = 'transparent',
    ROUND = 'round',
    SQUARE = 'square',
    FULLSIZE = 'fullsize',
}

export enum StrokeSize {
    NONE = 'none',
    S = 's',
    M = 'm',
    L = 'l',
}

export enum TextBoxType {
    REGULAR = 'regular',
    ROUNDED = 'rounded',
    BEVELED = 'beveled',
    INCLINED = 'inclined'
}

export enum TextPosition {
    TOP = 'top',
    BOTTOM = 'bottom',
}

export enum VisualEffect {
    NONE = 'none',

    BACK_IN_DOWN = 'backInDown',
    BACK_IN_LEFT = 'backInLeft',
    BACK_IN_RIGHT = 'backInRight',
    BACK_IN_UP = 'backInUp',

    BOUNCE_IN = 'bounceIn',
    BOUNCE_IN_DOWN = 'bounceInDown',
    BOUNCE_IN_LEFT = 'bounceInLeft',
    BOUNCE_IN_RIGHT = 'bounceInRight',
    BOUNCE_IN_UP = 'bounceInUp',

    FADE_IN = 'fadeIn',
    FADE_IN_DOWN = 'fadeInDown',
    FADE_IN_DOWN_BIG = 'fadeInDownBig',
    FADE_IN_LEFT = 'fadeInLeft',
    FADE_IN_LEFT_BIG = 'fadeInLeftBig',
    FADE_IN_RIGHT = 'fadeInRight',
    FADE_IN_RIGHT_BIG = 'fadeInRightBig',
    FADE_IN_UP = 'fadeInUp',
    FADE_IN_UP_BIG = 'fadeInUpBig',
    FADE_IN_TOP_LEFT = 'fadeInTopLeft',
    FADE_IN_TOP_RIGHT = 'fadeInTopRight',
    FADE_IN_BOTTOM_LEFT = 'fadeInBottomLeft',
    FADE_IN_BOTTOM_RIGHT = 'fadeInBottomRight',

    ROTATE_IN = 'rotateIn',
    ROTATE_IN_DOWN_LEFT = 'rotateInDownLeft',
    ROTATE_IN_DOWN_RIGHT = 'rotateInDownRight',
    ROTATE_IN_UP_LEFT = 'rotateInUpLeft',
    ROTATE_IN_UP_RIGHT = 'rotateInUpRight',

    ZOOM_IN = 'zoomIn',
    ZOOM_IN_DOWN = 'zoomInDown',
    ZOOM_IN_LEFT = 'zoomInLeft',
    ZOOM_IN_RIGHT = 'zoomInRight',
    ZOOM_IN_UP = 'zoomInUp',

    SLIDE_IN_DOWN = 'slideInDown',
    SLIDE_IN_LEFT = 'slideInLeft',
    SLIDE_IN_RIGHT = 'slideInRight',
    SLIDE_IN_UP = 'slideInUp',

    BACK_OUT_DOWN = 'backOutDown',
    BACK_OUT_LEFT = 'backOutLeft',
    BACK_OUT_RIGHT = 'backOutRight',
    BACK_OUT_UP = 'backOutUp',

    BOUNCE_OUT = 'bounceOut',
    BOUNCE_OUT_DOWN = 'bounceOutDown',
    BOUNCE_OUT_LEFT = 'bounceOutLeft',
    BOUNCE_OUT_RIGHT = 'bounceOutRight',
    BOUNCE_OUT_UP = 'bounceOutUp',

    FADE_OUT = 'fadeOut',
    FADE_OUT_DOWN = 'fadeOutDown',
    FADE_OUT_DOWN_BIG = 'fadeOutDownBig',
    FADE_OUT_LEFT = 'fadeOutLeft',
    FADE_OUT_LEFT_BIG = 'fadeOutLeftBig',
    FADE_OUT_RIGHT = 'fadeOutRight',
    FADE_OUT_RIGHT_BIG = 'fadeOutRightBig',
    FADE_OUT_UP = 'fadeOutUp',
    FADE_OUT_UP_BIG = 'fadeOutUpBig',
    FADE_OUT_TOP_LEFT = 'fadeOutTopLeft',
    FADE_OUT_TOP_RIGHT = 'fadeOutTopRight',
    FADE_OUT_BOTTOM_LEFT = 'fadeOutBottomLeft',
    FADE_OUT_BOTTOM_RIGHT = 'fadeOutBottomRight',

    ROTATE_OUT = 'rotateOut',
    ROTATE_OUT_DOWN_LEFT = 'rotateOutDownLeft',
    ROTATE_OUT_DOWN_RIGHT = 'rotateOutDownRight',
    ROTATE_OUT_UP_LEFT = 'rotateOutUpLeft',
    ROTATE_OUT_UP_RIGHT = 'rotateOutUpRight',

    ZOOM_OUT = 'zoomOut',
    ZOOM_OUT_DOWN = 'zoomOutDown',
    ZOOM_OUT_LEFT = 'zoomOutLeft',
    ZOOM_OUT_RIGHT = 'zoomOutRight',
    ZOOM_OUT_UP = 'zoomOutUp',

    SLIDE_OUT_DOWN = 'slideOutDown',
    SLIDE_OUT_LEFT = 'slideOutLeft',
    SLIDE_OUT_RIGHT = 'slideOutRight',
    SLIDE_OUT_UP = 'slideOutUp',
}

export enum VisualEffectSpeed {
    SLOW = 'slow',
    MEDIUM = 'medium',
    FAST = 'fast',
}

export interface IAlertStyle {
    id?: string;
    _id?: string;
    name: string;
    isSystem: boolean;
    // -----------------
    layout: AlertLayout;
    duration: number;
    avatar: IStreamojiStyle;
    title: ITitleStyle;
    text: ITextStyle;
    shadow: IShadowStyle;
    textbox: ITextBoxStyle;
    graphics: IGraphicsStyle;
    // -----------------
    creatorId?: string;
    createdAt?: number;
    updatedAt?: number;
    deleted?: boolean;
    deletedAt?: number;
}

export interface IFillStyle {
    fillType: FillType;
    direction?: number;
    primaryColor: string;
    secondaryColor?: string;
}

export interface IGraphicsStyle {
    enabled: boolean;
    position: GraphicsPosition;
    source: string;
    effects: IVisualEffects;
}

export interface IShadowStyle {
    enabled: boolean;
    fill: IFillStyle;
}

export interface IStreamojiStyle {
    enabled: boolean;
    type: StreamojiType;
    position: StreamojiPosition;
    size: StreamojiSize;
    stroke: IStrokeStyle;
    effects: IVisualEffects;
}

export interface IStrokeStyle {
    enabled: boolean;
    size: StrokeSize;
    fill: IFillStyle;
}

export interface ITextBoxStyle {
    enabled: boolean;
    type: TextBoxType;
    opacity: number;
    fill: IFillStyle;
    stroke: IStrokeStyle;
    effects: IVisualEffects;
}

export interface ITextStyle {
    enabled: boolean;
    fontFamily: string;
    fontSize: FontSize;
    fill: IFillStyle;
    variablesFill: IFillStyle;
    stroke: IStrokeStyle;
    text: string;
    effects: IVisualEffects;
}

export interface ITitleStyle {
    enabled: boolean;
    fontFamily: string;
    fontSize: FontSize;
    fill: IFillStyle;
    variablesFill: IFillStyle;
    stroke: IStrokeStyle;
    position: TextPosition;
    text: string;
    effects: IVisualEffects;
}

export interface IVisualEffect {
    effect: VisualEffect;
    speed: VisualEffectSpeed;
}

export interface IVisualEffects extends Record<'in' | 'out', IVisualEffect> {}

export interface IAlertTypeItem {
    id: string;
    _id?: string;
    type: AlertType;
    isActive: boolean;
    offsetX: number;
    offsetY: number;
    position: AlertPosition
    size: AlertSize;
    alertStyle: IAlertStyle;
}

export type TAlertStyleList = Array<IAlertStyle>;

export type TAlertsList = Array<IAlertTypeItem>;
