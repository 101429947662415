import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconShitLine } from '@assets/images/svg';
import { ISticker, IUser } from '@typings';

import s from './ControlBlacklist.scss';
import { useControlBlacklist } from './hooks';
import { ControlItem } from '../ControlItem';


interface IControlBlacklistProps {
    currentUser?: IUser;
    sticker: ISticker;
    updateSticker(stickerData: ISticker): void;
}

export const ControlBlacklist = (props: IControlBlacklistProps) => {
    const {
        sticker,
        currentUser,
        updateSticker,
    } = props;
    const {
        onBlacklistClick,
    } = useControlBlacklist({
        sticker,
        currentUser,
        updateSticker,
    });
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.controls.${id}`);

    return (
        <ControlItem
            classNames={{
                root: cn(s.root, s.blacklist),
                icon: cn({ [s.blacklistedIcon]: sticker.customSettings?.disableSticker }),
                title: cn({ [s.blacklistedTitle]: sticker.customSettings?.disableSticker }),
            }}
            title={sticker.customSettings?.disableSticker ? t('blacklisted') : t('blacklist')}
            icon={<IconShitLine/>}
            onClick={onBlacklistClick}
        />
    );
};
