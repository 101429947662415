// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TitleForm__text--G8tHv {
  width: 100%;
}

.TitleForm__positionItem--TMF0l {
  width: 70px;
  margin-right: 25px;
}

.TitleForm__fontFamily--wzMwa {
  width: 260px;
}

.TitleForm__size--qH3LZ,
.TitleForm__stroke--QXsTx,
.TitleForm__fade--b_nCe {
  margin-right: 10px;
}
.TitleForm__sizeSelect--MG0_U,
.TitleForm__strokeSelect--UbzqY,
.TitleForm__fadeSelect--V884g {
  width: 75px;
  margin-right: 5px;
}
.TitleForm__sizeSelectControlInput--e5yVS,
.TitleForm__strokeSelectControlInput--SSEE7,
.TitleForm__fadeSelectControlInput--V5m1L {
  min-width: 20px;
}

.TitleForm__colorPicker--lTswt {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Elements/components/FormSwitcher/components/forms/TitleForm/TitleForm.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAGI;EACI,WAAA;EACA,kBAAA;AAAR;;AAIA;EACI,YAAA;AADJ;;AAIA;;;EAGI,kBAAA;AADJ;AAGI;;;EACI,WAAA;EACA,iBAAA;AACR;AACQ;;;EACI,eAAA;AAGZ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":[".text {\r\n    width: 100%;\r\n}\r\n\r\n.position {\r\n    &Item {\r\n        width: 70px;\r\n        margin-right: 25px;\r\n    }\r\n}\r\n\r\n.fontFamily {\r\n    width: 260px;\r\n}\r\n\r\n.size,\r\n.stroke,\r\n.fade {\r\n    margin-right: 10px;\r\n\r\n    &Select {\r\n        width: 75px;\r\n        margin-right: 5px;\r\n\r\n        &ControlInput {\r\n            min-width: 20px;\r\n        }\r\n    }\r\n}\r\n\r\n.colorPicker {\r\n    margin-right: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `TitleForm__text--G8tHv`,
	"positionItem": `TitleForm__positionItem--TMF0l`,
	"fontFamily": `TitleForm__fontFamily--wzMwa`,
	"size": `TitleForm__size--qH3LZ`,
	"stroke": `TitleForm__stroke--QXsTx`,
	"fade": `TitleForm__fade--b_nCe`,
	"sizeSelect": `TitleForm__sizeSelect--MG0_U`,
	"strokeSelect": `TitleForm__strokeSelect--UbzqY`,
	"fadeSelect": `TitleForm__fadeSelect--V884g`,
	"sizeSelectControlInput": `TitleForm__sizeSelectControlInput--e5yVS`,
	"strokeSelectControlInput": `TitleForm__strokeSelectControlInput--SSEE7`,
	"fadeSelectControlInput": `TitleForm__fadeSelectControlInput--V5m1L`,
	"colorPicker": `TitleForm__colorPicker--lTswt`
};
export default ___CSS_LOADER_EXPORT___;
