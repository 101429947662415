import { Button, Column, EComponentColor, ERowJustification, Row } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import { t } from 'i18next';
import React, { forwardRef, useState } from 'react';

import s from './List.scss';

interface IListProps {
    actionButtonCaption?: string;
    className?: string;
    items: Array<string>;
    withActionButton?: boolean;
    onActionButtonClick?: () => void;
    onSelectionChange?: (selection: Array<string>, indexes: Array<number>) => void;
}

export const List = forwardRef<HTMLDivElement, IListProps>((props, ref) => {
    const {
        actionButtonCaption,
        className,
        items,
        withActionButton,
        onActionButtonClick = noop,
        onSelectionChange = noop,
    } = props;

    const [selectedValues, setSelectedValues] = useState<Array<string>>([]);
    const [selectedValuesIndexes, setSelectedValuesIndexes] = useState<Array<number>>([]);

    const onItemClick = (item: string, idx: number, e: React.MouseEvent) => {
        e.stopPropagation();

        const alreadySelected = selectedValuesIndexes.includes(idx);

        const newValues = alreadySelected
            ? selectedValues.filter((value) => value !== item)
            : selectedValues.concat([item]);
        const newIndexes = alreadySelected
            ? selectedValuesIndexes.filter((i) => i !== idx)
            : selectedValuesIndexes.concat([idx]);
        setSelectedValues(newValues);
        setSelectedValuesIndexes(newIndexes);
        onSelectionChange(newValues, newIndexes);
    };

    const listItems = items.map((item, idx) => {
        return (
            <div
                key={idx}
                className={cn(
                    s.listItem,
                    { [s.listItemActive]: selectedValuesIndexes.includes(idx) },
                )}
                onClick={(e) => onItemClick(item, idx, e)}
            >
                <div className={s.listItemCheck}/>
                <div>
                    {item}
                </div>
            </div>
        );
    });

    const listNameClasses = cn(s.list, className);

    return (
        <Column ref={ref} className={listNameClasses}>
            {listItems}

            {withActionButton &&
                <Row padding={{ top: 20, bottom: 10 }} justification={ERowJustification.CENTER}>
                    <Button
                        width={160} height={30}
                        caption={actionButtonCaption || t('common.ok')}
                        color={EComponentColor.ACCENT}
                        onClick={onActionButtonClick}
                    />
                </Row>
            }
        </Column>
    );
});

List.displayName = 'List';
