import {
    EOrientation,
    ERelativePosition,
} from '@hyperclap/ui';
import React from 'react';
import { useMatch } from 'react-router-dom';

import {
    IconBoltLine,
    IconMoneyWithdrawLine,
    IconPetardLine,
    IconShieldLine,
    IconSmileGrinLine,
    IconUserCircleLine,
    IconUsersAltLine,
    IconWebcamLine,
} from '@assets/images/svg';
import { Menu, MenuItem, MenuItemProps } from '@components';
import { TApp } from '@hooks';

import s from './LeftSideMenu.scss';


interface LeftSideMenuProps extends TApp {
    hideMenuActivation?: boolean;
}

const defaultMenuItemProps: MenuItemProps<string> = {
    className: s.leftSideMenuItem,
    iconClassName: s.leftSideMenuItemIcon,
    iconPosition: ERelativePosition.LEFT,
};

export const LeftSideMenu = (props: LeftSideMenuProps) => {
    const {
        navigation: {
            goToPath,
        },
        translation: {
            t,
        },
        hideMenuActivation,
    } = props;
    const isAlertsRootMatched = !!useMatch('/channel/alerts');
    const isAlertsTypeMatched = !!useMatch('/channel/alerts/:type');

    const menuStructure: MenuItemProps<string>[] = [
        {
            active: !!useMatch('/channel/page') && !hideMenuActivation,
            caption: t('streamer-settings.menu.page'),
            icon: <IconUserCircleLine/>,
            tag: '/channel/page',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/channel/obs') && !hideMenuActivation,
            caption: t('streamer-settings.menu.obs'),
            icon: <IconWebcamLine/>,
            tag: '/channel/obs',
            // redBulb: !currentUser?.channel.masteredObsSettings, //TODO: Add red bulb
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/channel/payments') && !hideMenuActivation,
            caption: t('streamer-settings.menu.payments'),
            icon: <IconMoneyWithdrawLine/>,
            tag: '/channel/payments',
            // redBulb: !currentUser?.channel?.donation AlertsUserId,
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/channel/referrals') && !hideMenuActivation,
            caption: t('streamer-settings.menu.referrals'),
            icon: <IconUsersAltLine/>,
            iconClassName: s.favortitesMenuItemIconSizeFix,
            tag: '/channel/referrals',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/channel/moderation') && !hideMenuActivation,
            caption: t('streamer-settings.menu.moderation'),
            icon: <IconShieldLine/>,
            tag: '/channel/moderation',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/channel/stickers') && !hideMenuActivation,
            caption: t('streamer-settings.menu.stickers'),
            icon: <IconSmileGrinLine/>,
            tag: '/channel/stickers',
            ...defaultMenuItemProps,
        },
        {
            active: (isAlertsRootMatched || isAlertsTypeMatched) && !hideMenuActivation,
            caption: t('streamer-settings.menu.alerts'),
            icon: <IconBoltLine/>,
            tag: '/channel/alerts',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/channel/meme-party') && !hideMenuActivation,
            caption: t('streamer-settings.menu.memeparty'),
            icon: <IconPetardLine/>,
            tag: '/channel/meme-party',
            ...defaultMenuItemProps,
        },
    ];

    const onMenuItemClick = (tag?: string) => {
        if (tag) {
            goToPath(tag);
        }
    };

    const menuItems = menuStructure.map((itemProps, idx) => (
        <MenuItem key={idx} {...itemProps} onClick={onMenuItemClick}/>
    ));

    return (
        <Menu orientation={EOrientation.VERTICAL} className={s.leftSideMenu}>
            {menuItems}
        </Menu>
    );
};
