import {
    Column,
    EComponentSize,
    Label,
    Loader,
    Row,
} from '@hyperclap/ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';


import { AlertType } from '@typings';

import s from './AlertsSettings.scss';
import { AlertTypeItem } from './components/AlertTypeItem';
import { useAlertsSettings } from './hooks';

export const AlertsSettings = () => {
    const {
        isLoading,
        alertTypes,
        onActiveChange,
    } = useAlertsSettings();
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts.${id}`);

    const handleActiveChange = (type: AlertType) => (value: boolean) => {
        onActiveChange(type, value);
    };

    return isLoading
        ? <Loader/>
        : (
            <Row className={s.root}>
                <Column className={s.content}>
                    <Label
                        className={s.title}
                        caption={t('alerts-section')}
                        size={EComponentSize.LARGE}
                    />


                    <Column className={s.items}>
                        {Object.values(AlertType).map((type) => {
                            const item = alertTypes?.find((item) => item.type === type);

                            return (
                                <AlertTypeItem
                                    key={type}
                                    type={type}
                                    item={item}
                                    onActiveChange={handleActiveChange(type)}
                                />
                            );
                        })}
                    </Column>
                </Column>

                <Column className={s.preview}></Column>
            </Row>
        );
};
