import cn from 'classnames';
import React from 'react';
import 'animate.css';

import { IAlertTypeItem } from '@typings';


import s from './Alert.scss';
import { Content } from './components/Content';
import { Image } from './components/Image';
import { EAnimationState } from './enums';

interface IAlertProps {
    classNames?: {
        root?: string;
        alert?: string;
    };
    alert: IAlertTypeItem;
    animationState?: EAnimationState;
    imageUrl: string;
    titleVariables: Record<string, string>;
    textVariables: Record<string, string>;
}

export const Alert = (props: IAlertProps) => {
    const {
        classNames = {},
        alert,
        imageUrl,
        titleVariables,
        textVariables,
        animationState,
    } = props;
    const {
        position,
        size,
        offsetX,
        offsetY,
        alertStyle,
    } = alert;
    const {
        layout,
        avatar,
    } = alertStyle;
    const alertStyles = {
        margin: `${offsetY}px ${offsetX}px`,
    };

    return (
        <div className={
            cn(
                s.root,
                s[`size-${size}`],
                classNames.root,
            )
        }>
            <div className={
                cn(
                    s.position,
                    s[`position-${position}`],
                    s[`align-${avatar.position}`],
                )
            }>
                <div
                    className={
                        cn(
                            s.alert,
                            s[`layout-${layout}`],
                            classNames.alert,
                        )
                    }
                    style={alertStyles}
                >
                    {avatar.enabled && (
                        <Image
                            className={s.image}
                            avatarStyle={avatar}
                            imageUrl={imageUrl}
                            animationState={animationState}
                        />
                    )}

                    <Content
                        className={s.content}
                        alert={alert}
                        titleVariables={titleVariables}
                        textVariables={textVariables}
                        animationState={animationState}
                    />
                </div>
            </div>
        </div>
    );
};
