import { muteApi } from '@store/app/web-api/mute.api';

import { alertsApi } from './alerts.api';
import { authApi } from './auth.api';
import { baseApi } from './base.api';
import { channelApi } from './channel.api';
import { contentMarksApi } from './content-marks.api';
import { dashboardApi } from './dashboard.api';
import { eventsApi } from './events.api';
import { invitesApi } from './invites.api';
import { memePartyApi } from './meme-party.api';
import { paymentsApi } from './payments.api';
import { settingsApi } from './settings.api';
import { stickersApi } from './stickers.api';
import { supportersApi } from './supporters.api';
import { telegramApi } from './telegram.api';
import { thematicSetsApi } from './thematic-sets.api';
import { usersApi } from './users.api';
import { voiceCategoryApi } from './voice-category.api';

export const api = {
    ...baseApi,
    alerts: { ...alertsApi },
    auth: { ...authApi },
    channel: { ...channelApi },
    contentMarks: { ...contentMarksApi },
    dashboard: { ...dashboardApi },
    events: { ...eventsApi },
    invites: { ...invitesApi },
    memeParty: { ...memePartyApi },
    mute: { ...muteApi },
    payments: { ...paymentsApi },
    settings: { ...settingsApi },
    stickers: { ...stickersApi },
    supporters: { ...supportersApi },
    telegram: { ...telegramApi },
    thematicSet: { ...thematicSetsApi },
    users: { ...usersApi },
    voices: { ...voiceCategoryApi },
};
