// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextForm__text--FMu7R {
  width: 100%;
}

.TextForm__fontFamily--qQNTk {
  width: 260px;
}

.TextForm__size--sk11Z,
.TextForm__stroke--_OuG2,
.TextForm__fade--FqGZB {
  margin-right: 10px;
}
.TextForm__sizeSelect--XcVxt,
.TextForm__strokeSelect--CBlkL,
.TextForm__fadeSelect--PEyFC {
  width: 75px;
  margin-right: 5px;
}
.TextForm__sizeSelectControlInput--CY5sE,
.TextForm__strokeSelectControlInput--NdlEx,
.TextForm__fadeSelectControlInput--muOIM {
  min-width: 20px;
}

.TextForm__colorPicker--jWvuo {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Elements/components/FormSwitcher/components/forms/TextForm/TextForm.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;;;EAGI,kBAAA;AACJ;AACI;;;EACI,WAAA;EACA,iBAAA;AAGR;AADQ;;;EACI,eAAA;AAKZ;;AAAA;EACI,iBAAA;AAGJ","sourcesContent":[".text {\r\n    width: 100%;\r\n}\r\n\r\n.fontFamily {\r\n    width: 260px;\r\n}\r\n\r\n.size,\r\n.stroke,\r\n.fade {\r\n    margin-right: 10px;\r\n\r\n    &Select {\r\n        width: 75px;\r\n        margin-right: 5px;\r\n\r\n        &ControlInput {\r\n            min-width: 20px;\r\n        }\r\n    }\r\n}\r\n\r\n.colorPicker {\r\n    margin-right: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `TextForm__text--FMu7R`,
	"fontFamily": `TextForm__fontFamily--qQNTk`,
	"size": `TextForm__size--sk11Z`,
	"stroke": `TextForm__stroke--_OuG2`,
	"fade": `TextForm__fade--FqGZB`,
	"sizeSelect": `TextForm__sizeSelect--XcVxt`,
	"strokeSelect": `TextForm__strokeSelect--CBlkL`,
	"fadeSelect": `TextForm__fadeSelect--PEyFC`,
	"sizeSelectControlInput": `TextForm__sizeSelectControlInput--CY5sE`,
	"strokeSelectControlInput": `TextForm__strokeSelectControlInput--NdlEx`,
	"fadeSelectControlInput": `TextForm__fadeSelectControlInput--muOIM`,
	"colorPicker": `TextForm__colorPicker--jWvuo`
};
export default ___CSS_LOADER_EXPORT___;
