import React, { CSSProperties, Fragment, ReactNode } from 'react';

interface IGetTemplatedTextParams {
    template: string;
    vars: Record<string, string>
    varsStyles?: CSSProperties;
    varsClassName?: string;
    maxLength?: number;
}

const AVAILABLE_KEYS = [
    'name',
    'nick',
    'value',
    'coins',
    'sticker',
];

export const renderTemplatedText = (params: IGetTemplatedTextParams) => {
    const {
        template,
        vars,
        varsStyles,
        varsClassName,
        maxLength,
    } = params;
    const words = template.split(' ');
    const result: ReactNode[] = [];
    let resultLength = 0;

    for (let i = 0; i < words.length; i++) {
        const item = words[i];
        const key = item
            .replace(/^%/, '')
            .replace(/%$/, '')
            .toLowerCase();

        if (AVAILABLE_KEYS.includes(key)) {
            const text = vars[key] || '';

            result.push(
                <Fragment key={i}>
                    <span
                        className={varsClassName}
                        style={varsStyles}
                    >
                        {text}
                    </span>
                    {' '}
                </Fragment>,
            );
            resultLength += text.length + 1;
        } else {
            result.push(
                <Fragment key={i}>
                    {item}
                    {' '}
                </Fragment>,
            );

            resultLength += item.length + 1;
        }

        if (maxLength && resultLength >= maxLength) {
            result.push(<>...</>);
            break;
        }
    }

    return result;
};
