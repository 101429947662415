import { useStickers } from '@hooks/app/stickers';
import { ISticker, IUser } from '@typings';

interface IUseControlBlacklistParams {
    sticker: ISticker;
    currentUser?: IUser;
    updateSticker(stickerData: ISticker): void;
}

export const useControlBlacklist = (params: IUseControlBlacklistParams) => {
    const {
        sticker,
        currentUser,
        updateSticker,
    } = params;
    const {
        changeStickerCustomSettings,
    } = useStickers({ streamerChannelName: currentUser?.channel?.name });

    const onBlacklistClick = async () => {
        const stickerCustomSettings = {
            ...sticker.customSettings,
            disableSticker: !sticker.customSettings?.disableSticker,
        };

        delete stickerCustomSettings._id;

        const updatedSticker = await changeStickerCustomSettings(
            sticker,
            stickerCustomSettings,
        );

        updateSticker(updatedSticker);
    };

    return {
        onBlacklistClick,
    };
};
