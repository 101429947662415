import {
    Button,
    Column,
    Row,
    EColumnAlignment,
    EComponentColor,
    ERowJustification,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useState } from 'react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ImageLandingLogo } from '@assets/images/png';
import { TApp } from '@hooks';
import { Footer } from '@memealerts/views';
import { Login, DesktopPage } from '@views';

import s from './Mobile.scss';
import { Header } from '../../components';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { VIDEO_DATA } from '../../const';

/** To remove *any* type. Provides information about properties of the swiper which is used in the code below */
interface ISwiperProxy {
    activeIndex: number;
}

interface IMobileProps extends TApp {}

export const Mobile = (props: IMobileProps) => {
    const {
        modals: {
            showModal,
        },
        translation: {
            t,
        },
    } = props;

    const [activeSlideIndex, setActiveSlideIndex] = useState(2);
    const [animationChanged, setAnimationChanged] = useState(false);

    const videos = VIDEO_DATA.map((video, idx) => (
        <video
            key={idx}
            id={s.swiperVideo + idx}
            className={s.swiperVideo}
            src={video.src}
            preload='metadata'
            controls={activeSlideIndex === 0}
        />
    ));

    const onChangeSlideStart = () => setAnimationChanged(true);

    const onChangeSlideEnd = (swiper: ISwiperProxy) => {
        setActiveSlideIndex(swiper.activeIndex);
        const prevVideo = document.getElementById(s.swiperVideo + (swiper.activeIndex - 1)) as HTMLVideoElement;

        if (prevVideo && prevVideo.currentTime > 0 && !prevVideo.paused && !prevVideo.ended) {
            prevVideo.pause();
        }

        const nextVideo = document.getElementById(s.swiperVideo + (swiper.activeIndex + 1)) as HTMLVideoElement;

        if (nextVideo && nextVideo.currentTime > 0 && !nextVideo.paused && !nextVideo.ended) {
            nextVideo.pause();
        }

        setAnimationChanged(false);
    };

    const onDescriptionClick = () => window.open(VIDEO_DATA[activeSlideIndex].externalLink, '_blank');

    const doLogin = (returnUrl?: string) => showModal(<Login {...props} returnUrl={returnUrl ?? location.pathname}/>);

    const header = <Header {...props}/>;
    const footer = <Footer {...props}/>;

    return (
        <DesktopPage header={header} footer={footer} sidebarWidth={0} className={s.page}>
            <Column grow alignment={EColumnAlignment.CENTER} className={s.content}>
                <Column grow width={336}>

                    <Row justification={ERowJustification.CENTER}>
                        <Column className={s.topBannerImage}>
                            <img alt='' className={s.topBannerImageOlyashaa} src={ImageLandingLogo} />
                        </Column>
                    </Row>

                    <Row margin={{ bottom: 20 }} className={s.topBannerHeader}>
                        {t('landing.tagline.spiceUp')}
                    </Row>

                    <Row margin={{ bottom: 20 }} className={s.topBannerDescription}>
                        {t('landing.tagline.tiredOf')}
                    </Row>

                    <Row margin={{ bottom: 60 }} justification={ERowJustification.CENTER}>
                        <Button
                            caption={'Подключить'}
                            color={EComponentColor.ACCENT}
                            style={{ width: 175, height: 45, borderRadius: 15 }}
                            onClick={() => doLogin()}
                        />
                    </Row>

                    <Row margin={{ bottom: 30 }} justification={ERowJustification.CENTER} className={s.rowTitle}>
                        {t('landing.tagline.newEra')}
                    </Row>

                    <Row className={s.videoContainer}>
                        <iframe
                            width={336}
                            height={189}
                            src="https://www.youtube.com/embed/58cVTsL1Q98"
                            title="MemeAlerts: Стримы + Мемы = ❤️"
                            style={{ border: 0 }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    </Row>

                    <Row columnGap={60} padding={{ top: 70, bottom: 40 }} justification={ERowJustification.STRETCH}>
                        <Column rowGap={20}>
                            <Row className={s.topBannerHeader}>
                                {t('landing.tagline.earnMoney')}
                            </Row>
                            <Row className={s.topBannerDescription}>
                                {t('landing.tagline.setPrice')}
                            </Row>
                        </Column>
                    </Row>

                    <Row className={s.rowTitle}>
                        {'Уже используют'}
                    </Row>

                    <Row grow padding={{ bottom: 15 }} justification={ERowJustification.CENTER}>
                        <Row className={s.swiper}>
                            <Swiper
                                width={335}
                                effect={'coverflow'}
                                centeredSlides={true}
                                loop={false}
                                slidesPerView={'auto'}
                                initialSlide={2}
                                coverflowEffect={{
                                    slideShadows: true,
                                    rotate: 2,
                                    stretch: 180,
                                    depth: 500,
                                    modifier: 1,
                                }}
                                focusableElements={'input, select, option, textarea, button, label'}
                                modules={[EffectCoverflow, Pagination, Navigation]}
                                className={s.swiperContainer}
                                onTransitionStart={onChangeSlideStart}
                                onTransitionEnd={onChangeSlideEnd}
                            >
                                {videos.map((el, i) =>
                                    <SwiperSlide key={i} className={s.swiperContainerSlide}>
                                        {el}
                                    </SwiperSlide>,
                                )}
                            </Swiper>
                            <div
                                onClick={onDescriptionClick}
                                className={cn(s.swiperDescription, { [s.swiperDescriptionChange]: animationChanged })}
                            >
                                <img alt='' className={s.swiperDescriptionLogo} src={VIDEO_DATA[activeSlideIndex].logo}/>
                                <div className={s.swiperDescriptionInfo}>
                                    <div className={s.swiperDescriptionInfoName}>{VIDEO_DATA[activeSlideIndex].name}</div>
                                    <div className={s.swiperDescriptionInfoFollowers}>
                                        {`${VIDEO_DATA[activeSlideIndex].followers} ${t('landing.followers')}`}
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Row>

                    <Row grow padding={{ bottom: 60 }} justification={ERowJustification.CENTER}>
                        <Button
                            caption={'Попробовать'}
                            color={EComponentColor.ACCENT}
                            style={{ width: 175, height: 45, borderRadius: 15 }}
                            onClick={() => doLogin()}
                        />
                    </Row>

                </Column>
            </Column>
        </DesktopPage>
    );
};
