import {
    Column,
    Button,
    Row,
    TextField,
    useModals,
    EComponentSize,
    EComponentColor,
    ITextFieldMethods, useLogger,
} from '@hyperclap/ui';
import cn from 'classnames';
import i18next, { t } from 'i18next';
import React, { createRef, useEffect, useState } from 'react';

import { ImageDefaultCurrency } from '@assets/images/png';
import { IconCloseLine } from '@assets/images/svg';
import { declensionUserCurrency, DEFAULT_NEWBIE_ACTION } from '@common';
import { Avatar } from '@components';
import { useApi } from '@hooks';
import { useSystemSettings } from '@hooks/app/settings/SystemSettings';
import { IUser } from '@typings';

import s from './BuyCurrencyPopup.scss';
import { CurrencyPack, ICurrencyPackProps } from './components';


interface BuyCurrencyPopupProps {
    streamer: IUser;
    currentUser: IUser;
    isMobileLayoutRecommended?: boolean;
}

type CurrencyPackDefinition = Omit<ICurrencyPackProps, 'valueText' | 'currencyImage'>;
type CurrencyPackDefinitions = CurrencyPackDefinition[];

const MIN_CUSTOM_VALUE = 5;

const roubleSign = '₽';

export const BuyCurrencyPopup = (props: BuyCurrencyPopupProps) => {
    const packs: CurrencyPackDefinitions = [
        { value: 5, price: 50 },
        { value: 15, price: 145 },
        { value: 50, price: 450 },
        { value: 100, price: 850 },
        { value: 250, price: 2000, isPopular: true, badgeText: `${t('common.popular')}` },
        { value: 500, price: 3800 },
        {
            value: 1000, price: 7000, oldPrice: 10000, isAction: true, isFullwidth: true,
            badgeText: `${t('common.sale-action')} -30%`,
        },
    ];

    const logger = useLogger({ target: BuyCurrencyPopup.name, showTimestamp: true });
    const { closeModal } = useModals();
    const {
        payments: {
            useLazyGetPaymentUrlQuery,
            useHasPaymentsQuery,
        },
    } = useApi();

    const {
        streamer,
        currentUser,
        isMobileLayoutRecommended,
    } = props;

    const settings = useSystemSettings({ active: !!currentUser });

    const {
        data: hasPayments,
        isFetching: hasPaymentsFetching,
        isSuccess: hasPaymentsLoaded,
    } = useHasPaymentsQuery({ onChannel: streamer.id }, { refetchOnMountOrArgChange: true });
    const [buyCurrency] = useLazyGetPaymentUrlQuery();

    const [selectedPack, setSelectedPack] = useState(-2);
    const [customValue, setCustomValue] = useState<number>(25);
    const [customPrice, setCustomPrice] = useState<number>();
    const [selectedValue, setSelectedValue] = useState<number>();
    const [selectedPrice, setSelectedPrice] = useState<number>();
    const [badValue, setBadValue] = useState(false);
    const [sendBuySticker, setSendBuySticker] = useState(true);
    const [currencyPacks, setCurrencyPacks] = useState<CurrencyPackDefinitions>(packs);

    const [paywallUrl, setPaywallUrl] = useState<string>();
    const [paywallUrlLoaded, setPaywallUrlLoaded] = useState(false);

    const customValueMethodsRef = createRef<ITextFieldMethods>();

    const items = currencyPacks.map((pack, idx) => <CurrencyPack
        {...pack}
        key={idx}
        valueText={declensionUserCurrency(pack.value, streamer)}
        currencyImage={streamer.channel?.currencyImageUrl ?? ''}
        isSelected={selectedPack === idx}
        isMobileLayoutRecommended={isMobileLayoutRecommended}
        hideCurrencyPicture={isMobileLayoutRecommended && pack.value <=50}
        onClick={() => setSelectedPack(idx)}
    />);

    const getRate = (value: number) => {
        if (value < 15) return 10;
        if (value < 50) return 9.66;
        if (value < 100) return 9;
        if (value < 250) return 8.5;
        if (value < 500) return 8;
        if (value < 1000) return 7.6;

        return 7;
    };

    const onCustomValueChanged = (value: string) => {
        if (!value) {
            setBadValue(true);
            setCustomPrice(undefined);
        } else {
            setBadValue(false);
            setCustomValue(Number(value));
        }
    };

    const onCustomValueLeave = (value: string) => {
        if (Number(value) < MIN_CUSTOM_VALUE) {
            setCustomValue(MIN_CUSTOM_VALUE);
            customValueMethodsRef.current?.set('5');
        }
    };

    const onBuyButtonHover = () => {
        if (customValue < MIN_CUSTOM_VALUE) {
            setCustomValue(MIN_CUSTOM_VALUE);
            customValueMethodsRef.current?.set(MIN_CUSTOM_VALUE.toString());
        }
    };

    const onBuyClick = async () => {
        if (streamer && paywallUrl && paywallUrlLoaded) {
            window.open(paywallUrl, '_blank');
        }
    };

    const onPaymentCallbackMessage = async (event: MessageEvent) => {
        try {
            const message = JSON.parse(event.data as string);

            if (message.action === 'paymentState') {
                const status = message.status.toLowerCase();

                if (status === 'success') {
                    closeModal();
                }
            }
        } catch (error) {
            logger.debug(error);
        }
    };

    const requestPaywall = async (selectedPack: number, selectedValue?: number) => {
        if (
            currentUser &&
            (
                (streamer && selectedPack > -1 && selectedValue) ||
                (streamer && selectedPack === -1 && selectedValue && selectedValue >= MIN_CUSTOM_VALUE)
            )
        ) {
            setPaywallUrlLoaded(false);
            buyCurrency({
                toChannel: streamer.id,
                value: selectedValue,
                preventStreamerNotification: !sendBuySticker,
                isNewbieAction: !hasPayments && selectedPack === 0,
            }).unwrap().then((result) => {
                setPaywallUrl(result.url);
                setPaywallUrlLoaded(true);
            });
        }
    };

    useEffect(() => {
        if (customValue) {
            setCustomPrice(Math.ceil(customValue * getRate(customValue)));
            setSelectedValue(customValue);
            setSelectedPrice(Math.ceil(customValue * getRate(customValue)));
            if (customValue >= MIN_CUSTOM_VALUE) requestPaywall(selectedPack, customValue);
        }
    }, [customValue]);

    useEffect(() => {
        if (selectedPack >= 0) {
            setSelectedValue(currencyPacks[selectedPack].value);
            setSelectedPrice(currencyPacks[selectedPack].price);
            requestPaywall(selectedPack, currencyPacks[selectedPack].value);
        } else if (selectedPack === -1) {
            setSelectedValue(customValue);
            setSelectedPrice(customPrice);
            if (customValue >= MIN_CUSTOM_VALUE) requestPaywall(selectedPack, customValue);
        }
    }, [selectedPack]);

    useEffect(() => {
        void requestPaywall(selectedPack, selectedValue);
    }, [sendBuySticker]);

    useEffect(() => {
        const newbieActionEnabledGlobally = settings.asBool('bonuses.enableNewbieAction');

        if (!hasPaymentsFetching && hasPaymentsLoaded && streamer.channel?.newbieActionEnabled && newbieActionEnabledGlobally) {
            // TODO: verify
            // eslint-disable-next-line sonarjs/no-gratuitous-expressions
            const newbieBonus = !hasPayments && streamer.channel?.newbieActionEnabled && newbieActionEnabledGlobally;

            setCurrencyPacks(
                newbieBonus
                    ? packs.map(
                        (p, idx) => idx === 0
                            ? {
                                isBonus: true,
                                value: streamer.channel?.newbieAction
                                    ? streamer.channel?.newbieAction
                                    : streamer.channel?.catalogueStickerPrice
                                        ? streamer.channel?.catalogueStickerPrice + 1
                                        : DEFAULT_NEWBIE_ACTION,
                                price: 1,
                                badgeText: 'Бонус новичкам',
                            }
                            : p,
                    )
                    : packs,
            );
        }
    }, [hasPayments, hasPaymentsLoaded, settings.isLoaded]);

    useEffect(() => {
        window.addEventListener('message', onPaymentCallbackMessage);

        return () => {
            window.removeEventListener('message', onPaymentCallbackMessage);
        };
    }, []);

    return (
        <Column className={s.buyCurrencyPopup}>
            <Column className={s.buyCurrencyPopupContent}>
                <Row className={cn(s.popupRow, s.streamerInfo)}>
                    <Avatar
                        className={s.streamerInfoAvatar}
                        source={streamer.channel.avatarUrl}
                        size={EComponentSize.MEDIUM}
                        rounded
                    />
                    <div className={s.streamerInfoName}>
                        {streamer.channel.name}
                    </div>
                </Row>

                {!isMobileLayoutRecommended &&
                    <Row className={cn(s.popupRow, s.description)}>
                        {
                            streamer?.channel.paywallText ||
                            `${streamer.channel?.currencyNameDeclensions?.multiple.nominative} ${
                                t('buy-currency-popup.virtual-currency')
                            } ${
                                streamer.channel?.name
                            }. ${
                                t('buy-currency-popup.channel-development')
                            }`
                        }
                    </Row>
                }

                <Row className={cn(s.popupRow, s.packs)}>
                    {items}
                </Row>
                <div
                    className={cn(
                        s.popupRow,
                        s.customPrice,
                        {
                            [s.customPriceSelected]: selectedPack === -1,
                        },
                    )}
                    onClick={() => setSelectedPack(-1)}
                >
                    <Column className={s.customPriceValue}>
                        <Row className={s.customPriceValueLabel}>
                            {`${
                                t('buy-currency-popup.own-value')
                            } (${t('buy-currency-popup.own-value-min')} ${MIN_CUSTOM_VALUE})`}
                        </Row>
                        <Row>
                            <Avatar
                                rounded
                                source={streamer.channel.currencyImageUrl || ImageDefaultCurrency}
                                fallbackImage={ImageDefaultCurrency}
                                size={EComponentSize.SMALL}
                            />
                            <TextField
                                className={cn(
                                    s.customPriceValueValue,
                                    {
                                        [s.customPriceValueValueError]: customValue < MIN_CUSTOM_VALUE,
                                    },
                                )}
                                defaultValue={'25'}
                                invalidSymbols={/[^0-9]/gi}
                                debounceTime={200}
                                onChanged={onCustomValueChanged}
                                onLeave={onCustomValueLeave}
                                onEnter={() => setSelectedPack(-1)}
                                methodsRef={customValueMethodsRef}
                            />
                        </Row>
                    </Column>
                    <Column className={s.customPriceEquals}>
                        {'='}
                    </Column>
                    <Column className={s.customPricePrice}>
                        <Row className={s.customPricePriceLabel}>
                            {t('common.price')}
                        </Row>
                        <Row className={s.customPricePriceValue}>
                            <div>{i18next.language === 'en' ? '$' : ''}</div>
                            <div>{customPrice}</div>
                            <div>{i18next.language !== 'en' ? roubleSign : ''}</div>
                        </Row>
                    </Column>
                </div>
                <Row className={cn(s.popupRow, s.button)}>
                    <Button
                        className={cn({
                            [s.buttonDisableEvents]: !paywallUrlLoaded,
                        })}
                        caption={
                            !selectedValue || !selectedPrice || badValue || selectedPack < -1
                                ? t('buy-currency-popup.choose-case')
                                : <div className={s.buttonCaption}>
                                    <div>{t('common.buy')}</div>
                                    <Avatar
                                        rounded
                                        className={s.buttonIcon}
                                        source={streamer.channel.currencyImageUrl || ImageDefaultCurrency}
                                        fallbackImage={ImageDefaultCurrency}
                                        size={EComponentSize.EXTRA_SMALL}
                                    />
                                    <div>{selectedValue}</div>
                                </div>
                        }
                        color={EComponentColor.ACCENT}
                        disabled={!selectedValue || !selectedPrice || badValue || selectedPack < -1}
                        onClick={onBuyClick}
                        onHover={onBuyButtonHover}
                    />
                </Row>
                <Row columnGap={7} className={cn(s.popupRow, s.checkbox)}>
                    {streamer.channel.isPopupNewSaleEnabled
                        ? <>
                            <input
                                type='checkbox'
                                className={s.sendBuySticker}
                                checked={sendBuySticker}
                                onChange={() => setSendBuySticker((prev) => !prev)}
                            />
                            <div className={s.checkboxLabel}>{t('buy-currency-popup.show-purchase-alert')}</div>
                        </>
                        : <div className={s.checkboxLabel}>{t('buy-currency-popup.purchase-alert-disabled')}</div>
                    }
                </Row>
            </Column>

            <div
                className={cn(
                    s.closeButton,
                    {
                        [s.closeButtonMobileLayout]: isMobileLayoutRecommended,
                    },
                )}
                onClick={closeModal}
            >
                <IconCloseLine/>
            </div>
        </Column>
    );
};
