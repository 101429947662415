import {
    Button,
    Column,
    EColumnAlignment,
    EColumnJustification,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    Icon,
    Label,
    Row,
    ScrollableArea,
    Select,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconCheckLine, IconPauseLine, IconPlayLine } from '@assets/images/svg';
import { useCurrentUser } from '@hooks';
import { IVoiceDescriptor } from '@typings';

import s from './VoicesList.scss';
import { useVoices } from '../../hooks';

export const VoicesList = () => {
    const {
        currentUser,
    } = useCurrentUser();

    const {
        TEST_PHRASE,
        playedVoiceId,
        selectedCategory,
        voicesCategories,
        voicesInCategory,
        playVoice,
        stopVoicePlaying,
        setSelectedCategory,
        updateUserVoice,
    } = useVoices();

    const {
        t: translate,
    } = useTranslation();

    const player = useRef<HTMLAudioElement>(null);

    const t = (token: string) => translate(`avatar-editor.voices-list.${token}`);

    const onCategoryChanged = (categoryId: string) => {
        stopVoicePlaying(player);
        setSelectedCategory(categoryId);
    };

    const onPlayClick = (descriptor: IVoiceDescriptor) => {
        if (playedVoiceId && playedVoiceId === descriptor.id) {
            stopVoicePlaying(player);
        } else {
            playVoice(descriptor, TEST_PHRASE, player);
        }
    };

    const options = voicesCategories?.map((category) => ({
        caption: category.name,
        value: category.id,
        selected: category.id === selectedCategory,
    })) ?? [];

    const voices = voicesInCategory?.map((descriptor) => (
        <Row columnGap={10} key={descriptor.id} alignment={ERowAlignment.STRETCH} className={s.voice}>
            <Column
                alignment={EColumnAlignment.CENTER}
                justification={EColumnJustification.CENTER}
                onClick={() => onPlayClick(descriptor)}
                className={s.clickable}
            >
                <Icon
                    className={cn(s.playIcon, { [s.playIconActive]: playedVoiceId === descriptor.id })}
                    icon={playedVoiceId === descriptor.id ? <IconPauseLine/> : <IconPlayLine/>}
                    color={EComponentColor.WHITE}
                    size={EComponentSize.EXTRA_SMALL}
                />
            </Column>
            <Column onClick={() => onPlayClick(descriptor)} className={s.clickable}>
                <Label
                    caption={descriptor.name}
                    size={EComponentSize.MEDIUM}
                    className={cn(s.name, { [s.nameActive]: playedVoiceId === descriptor.id })}
                />
            </Column>
            <Column grow onClick={() => onPlayClick(descriptor)} className={s.clickable}/>
            <Column>
                <Button
                    icon={<IconCheckLine/>}
                    className={cn(s.selectBtn, { [s.selectBtnSelected]: descriptor.id === currentUser?.voice?.id })}
                    onClick={() => updateUserVoice(descriptor)}
                />
            </Column>
        </Row>
    ));

    return (
        <Column grow alignment={EColumnAlignment.STRETCH} justification={EColumnJustification.STRETCH}>
            <audio ref={player}/>
            <Row>
                <Column>
                    <Label padding={{ top: 0 }} caption={t('category')} size={EComponentSize.MEDIUM}/>
                    <Select
                        width={400}
                        options={options}
                        onValueChanged={(value) => onCategoryChanged(value?.toString() ?? '')}
                    />
                </Column>
            </Row>
            <Column
                grow
                height={365}
                width={400}
                padding={{ top: 20, bottom: 20 }}
                alignment={EColumnAlignment.STRETCH}
            >
                <ScrollableArea contentClassName={s.scrollableAreaContent}>
                    {voices}
                </ScrollableArea>
            </Column>
        </Column>
    );
};
