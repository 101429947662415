import { EComponentColor, Switch } from '@hyperclap/ui';
import * as React from 'react';

import s from './FieldSwitcher.scss';
import { Field } from '../Field';

interface IFieldSwitcherProps {
    label: string;
    checked?: boolean;
    onChange?(value: boolean): void;
}

export const FieldSwitcher = (props: IFieldSwitcherProps) => {
    const {
        label,
        checked,
        onChange,
    } = props;

    return (
        <Field
            content={(
                <Switch
                    className={s.switch}
                    caption={label}
                    captionClassName={s.caption}
                    color={EComponentColor.ACCENT}
                    checked={checked}
                    onChange={onChange}
                />
            )}
        />
    );
};
