import { Column, Label, Loader } from '@hyperclap/ui';
import React, { useEffect } from 'react';

import { useAppActions, useAppContext, useElementOnScreen } from '@hooks';
import { useAppSelector } from '@hooks/store/AppSelector';
import { ISticker, StickerSendTopic } from '@typings';
import { Empty, StickersBand, StickersGrid } from '@views';

import s from './StreamerPage.scss';

export const StreamerPage = () => {
    const app = useAppContext();
    const {
        currentUser: {
            currentUser,
        },
        deviceInfo: {
            isAppleMobileDevice,
            isMobileLayoutRecommended,
        },
        navigation: {
            goToStreamerPageLastSent,
            goToStreamerPageFavorites,
            goToStreamerPageCreated,
            goToStreamerPagePopular,
            goToStreamerPageChannel,
            goToStreamerPageThematicSet,
        },
        stickers: {
            setStickerToSend,
        },
        streamer: {
            streamer,
        },
        streamerArea: {
            stickers: {
                catalogue: {
                    isEnd,
                    isFetching,
                    isUninitialized,
                    stickers: catalogueStickers,
                    loadNextPage,
                },
                channel: {
                    stickers: channelStickers,
                },
                favorites: {
                    stickers: favoriteStickers,
                },
                lastSent: {
                    stickers: lastSentStickers,
                },
                mine: {
                    stickers: mineStickers,
                },
                popular: {
                    stickers: popularStickers,
                },
                thematicSets: {
                    thematicSets,
                    thematicSetsStickers,
                },
            },
        },
        translation: {
            t,
        },
    } = app;

    const showAllMinStickersCount = isMobileLayoutRecommended ? 3 : 6;

    const playSoundOnStickerHover = useAppSelector((state) => state.app.playSoundOnStickerHover);
    const { switchPlaySoundOnStickerHover } = useAppActions();

    const {
        elementRef: watchdogRef,
        isOnScreen: needToLoadPage,
    } = useElementOnScreen<HTMLDivElement>({ threshold: 0 });

    const onStickerClick = (sticker: ISticker, topic: StickerSendTopic) => {
        if (streamer) {
            setStickerToSend(sticker, streamer, topic);
        }
    };

    useEffect(() => {
        if (needToLoadPage) {
            void loadNextPage();
        }
    }, [needToLoadPage]);

    const containerPadding = {
        left: isMobileLayoutRecommended ? 8 : 14,
        right: isMobileLayoutRecommended ? 8 : 4,
    };

    return (
        <Column padding={containerPadding}>
            {thematicSets.map((ts, idx) => (
                thematicSetsStickers[ts.id]?.length > 0 && <StickersBand
                    key={idx}
                    caption={ts.name}
                    isAppleMobileDevice={isAppleMobileDevice}
                    isMobileLayoutRecommended={isMobileLayoutRecommended}
                    defaultSoundVolume={currentUser?.channel?.soundVolume}
                    displayShowAll={lastSentStickers.length >= showAllMinStickersCount}
                    stickers={thematicSetsStickers[ts.id] ?? []}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={(sticker) => onStickerClick(sticker, StickerSendTopic.THEMATIC_SET)}
                    onShowMoreClick={() => goToStreamerPageThematicSet(streamer?.channel.link, ts.id)}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
            ))}

            {lastSentStickers.length > 0 && !streamer?.channel?.onlyChannelStickers &&
                <StickersBand
                    caption={t('streamer-area.my-sent')}
                    isAppleMobileDevice={isAppleMobileDevice}
                    isMobileLayoutRecommended={isMobileLayoutRecommended}
                    defaultSoundVolume={currentUser?.channel.soundVolume}
                    displayShowAll={lastSentStickers.length >= showAllMinStickersCount}
                    stickers={lastSentStickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={(sticker) => onStickerClick(sticker, StickerSendTopic.LAST)}
                    onShowMoreClick={() => goToStreamerPageLastSent(streamer?.channel.link)}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
            }

            {favoriteStickers.length > 0 && !streamer?.channel?.onlyChannelStickers &&
                <StickersBand
                    caption={t('streamer-area.favorites')}
                    isAppleMobileDevice={isAppleMobileDevice}
                    isMobileLayoutRecommended={isMobileLayoutRecommended}
                    defaultSoundVolume={currentUser?.channel.soundVolume}
                    displayShowAll={favoriteStickers.length >= showAllMinStickersCount}
                    stickers={favoriteStickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={(sticker) => onStickerClick(sticker, StickerSendTopic.FAVORITES)}
                    onShowMoreClick={() => goToStreamerPageFavorites(streamer?.channel.link)}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
            }

            {mineStickers.length > 0 && !streamer?.channel?.onlyChannelStickers &&
                <StickersBand
                    caption={t('streamer-area.created')}
                    isAppleMobileDevice={isAppleMobileDevice}
                    isMobileLayoutRecommended={isMobileLayoutRecommended}
                    defaultSoundVolume={currentUser?.channel.soundVolume}
                    displayShowAll={mineStickers.length >= showAllMinStickersCount}
                    stickers={mineStickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={(sticker) => onStickerClick(sticker, StickerSendTopic.MY_CREATED)}
                    onShowMoreClick={() => goToStreamerPageCreated(streamer?.channel.link)}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
            }

            {popularStickers.length > 0 && !streamer?.channel?.onlyChannelStickers &&
                <StickersBand
                    caption={t('streamer-area.last-sent')}
                    isAppleMobileDevice={isAppleMobileDevice}
                    isMobileLayoutRecommended={isMobileLayoutRecommended}
                    defaultSoundVolume={currentUser?.channel.soundVolume}
                    displayShowAll={popularStickers.length >= showAllMinStickersCount}
                    stickers={popularStickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={(sticker) => onStickerClick(sticker, StickerSendTopic.POPULAR)}
                    onShowMoreClick={() => goToStreamerPagePopular(streamer?.channel.link)}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
            }

            {channelStickers.length > 0 && !streamer?.channel?.onlyChannelStickers &&
                <StickersBand
                    caption={t('streamer-area.channel')}
                    isAppleMobileDevice={isAppleMobileDevice}
                    isMobileLayoutRecommended={isMobileLayoutRecommended}
                    defaultSoundVolume={currentUser?.channel.soundVolume}
                    displayShowAll={channelStickers.length >= showAllMinStickersCount}
                    stickers={channelStickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={(sticker) => onStickerClick(sticker, StickerSendTopic.CHANNEL)}
                    onShowMoreClick={() => goToStreamerPageChannel(streamer?.channel.link)}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
            }

            {catalogueStickers.length > 0 &&
                <Label
                    className={s.topStickersLabel}
                    caption={streamer?.channel.onlyChannelStickers ? t('streamer-area.channel') : t('streamer-area.top-stickers')}
                    useBoldFont
                />
            }
            <div style={{ minHeight: window.innerHeight - 170 }}>
                {catalogueStickers.length > 0
                    ? <StickersGrid
                        isAppleMobileDevice={isAppleMobileDevice}
                        isMobileLayoutRecommended={isMobileLayoutRecommended}
                        stickers={catalogueStickers}
                        stickersSoundEnabled={playSoundOnStickerHover}
                        defaultSoundVolume={currentUser?.channel?.soundVolume}
                        onStickerClick={
                            (sticker) => onStickerClick(sticker, streamer?.channel.onlyChannelStickers
                                ? StickerSendTopic.CHANNEL
                                : StickerSendTopic.TOP)
                        }
                        onSwitchSound={switchPlaySoundOnStickerHover}
                    />
                    : isFetching
                        ? <Loader margin={{ top: 50 }} className={s.loader} containerClassName={s.loaderContainer}/>
                        : !isUninitialized
                            ? <Empty text={'Здесь еще нет стикеров'}/>
                            : null
                }
            </div>

            {!isEnd && !isFetching &&
                <div className={s.nextPageWatchdog}>
                    <div className={s.nextPageWatchdogMarker} ref={watchdogRef}/>
                </div>
            }
        </Column>
    );
};
