import { Column, EComponentSize, ISelectOption, Label, Row, Select } from '@hyperclap/ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import s from './General.scss';
import { EDuration, EMargin } from '../../enums';
import { useAlertsSettingsType } from '../../hooks';
import { FormRow } from '../Elements/components/FormSwitcher/components/FormRow';
import { FormRowField } from '../Elements/components/FormSwitcher/components/FormRowField';
import { LayoutSwitcher } from '../LayoutSwitcher';

interface IGeneralProps {
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

export const General = (props: IGeneralProps) => {
    const {
        alertsSettingsType: {
            alertStyle: {
                offsetX,
                alertStyle: {
                    layout,
                    duration,
                },
            },
            alertDurationList,
            alertMarginList,
            onLayoutChange,
            onAlertDurationChange,
            onAlertMarginChange,
        },
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.${id}`);

    const handleAlertDurationChange = (selection?: ISelectOption) => {
        onAlertDurationChange(selection?.value as EDuration);
    };

    const handleAlertMarginChange = (selection?: ISelectOption) => {
        onAlertMarginChange(selection?.value as EMargin);
    };

    return (
        <Row className={s.root}>
            <Column className={s.column}>
                <FormRow>
                    <FormRowField>
                        <Label className={s.columnTitle} size={EComponentSize.SMALL} caption={t('layout')}/>

                        <LayoutSwitcher
                            layout={layout}
                            onChange={onLayoutChange}
                        />
                    </FormRowField>
                </FormRow>

                <FormRow>
                    <FormRowField>
                        <Label className={s.columnTitle} size={EComponentSize.SMALL} caption={t('margin')}/>

                        <Select
                            className={s.margin}
                            classNameControlInput={s.marginControlInput}
                            options={alertMarginList.map((item) => ({
                                caption: item.title,
                                value: item.value,
                                selected: offsetX === Number(item.value),
                            }))}
                            onSelectionChanged={handleAlertMarginChange}
                        />
                    </FormRowField>

                    <FormRowField>
                        <Label className={s.columnTitle} size={EComponentSize.SMALL} caption={t('duration')}/>

                        <Select
                            className={s.duration}
                            classNameControlInput={s.durationControlInput}
                            options={alertDurationList.map((item) => ({
                                caption: item.title,
                                value: item.value,
                                selected: duration === Number(item.value) * 1000,
                            }))}
                            onSelectionChanged={handleAlertDurationChange}
                        />
                    </FormRowField>
                </FormRow>
            </Column>
        </Row>
    );
};
