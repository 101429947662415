import { useStickers } from '@hooks/app/stickers';
import { ISticker, IUser } from '@typings';

interface IUseControlsStreamerParams {
    sticker: ISticker;
    currentUser?: IUser;
    updateSticker(stickerData: ISticker): void;
}

export const useControlsStreamer = (params: IUseControlsStreamerParams) => {
    const {
        currentUser,
        sticker,
        updateSticker,
    } = params;
    const {
        addStickerToFavorites,
        removeStickerFromFavorites,
        addStickerToChannel,
        removeStickerFromChannel,
    } = useStickers({ streamerChannelName: currentUser?.channel?.name });

    const onFavoritesClick = async () => {
        let updatedSticker: ISticker;

        if (sticker.isFavorite) {
            updatedSticker = await removeStickerFromFavorites(sticker);
        } else {
            updatedSticker = await addStickerToFavorites(sticker);
        }

        updateSticker(updatedSticker);
    };

    const onChannelClick = async () => {
        let updatedSticker: ISticker;

        if (sticker.isAddedToChannel) {
            updatedSticker = await removeStickerFromChannel(sticker);
        } else {
            updatedSticker = await addStickerToChannel(sticker);
        }

        updateSticker(updatedSticker);
    };

    return {
        onFavoritesClick,
        onChannelClick,
    };
};
