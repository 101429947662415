import { noop } from '@hyperclap/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isStickerSentEvent } from '@common';
import { NotificationType } from '@contexts';
import { useMute, useNotifications, useWebSocket } from '@hooks';
import { EventsFilterPreset } from '@hooks/app/events';
import { useApi } from '@hooks/webapi';
import {
    EventKind,
    IDashboardPeriodData,
    IHookBaseParams,
    IStickerRelatedEvent,
    IStickerSentEvent,
    IUser,
    Period,
    TMemeAlertsEvent,
    TWSMessage,
    WSMessageType,
} from '@typings';

export interface IDashboardParams extends IHookBaseParams {
    currentUser?: IUser;
}

const PAGE_SIZE = 100;

const defaultDashboardData: IDashboardPeriodData = {
    daysInfo: [],
    periodTotal: { earnings: 0, supportersCount: 0 },
};

export const useDashboard = (params: IDashboardParams) => {
    const {
        active,
        currentUser,
    } = params;

    const {
        dashboard: {
            useLazyLoadDashboardDataQuery,
        },
        events: {
            useLazyLoadDashboardEventsQuery,
        },
        stickers: {
            useLazyToggleStickerDisableStateQuery,
        },
    } = useApi();

    const {
        muteUser,
        unmuteUser,
    } = useMute({ streamerChannelName: currentUser?.id });

    const {
        notify,
    } = useNotifications();

    const {
        t,
    } = useTranslation();

    const { subscribe, unsubscribe } = useWebSocket();

    const [loadDashboardEvents, { isFetching: isDashboardEventsFetching }] = useLazyLoadDashboardEventsQuery();
    const [loadDashboardData] = useLazyLoadDashboardDataQuery();

    const [toggleStickerDisableState] = useLazyToggleStickerDisableStateQuery();

    const [dashboardData, setDashboardData] = useState<IDashboardPeriodData>(defaultDashboardData);
    const [dashboardEvents, setDashboardEvents] = useState<Array<TMemeAlertsEvent>>([]);
    const [period, setPeriod] = useState<Period>(Period.MONTH);
    const [eventsFilters, setEventsFilters] = useState<Array<EventsFilterPreset>>([
        EventsFilterPreset.PURCHASES,
        EventsFilterPreset.BONUSES,
        EventsFilterPreset.STICKERS,
        EventsFilterPreset.MEME_CANNON,
        EventsFilterPreset.FULLSCREEN,
        EventsFilterPreset.MODERATION,
    ]);

    const dashboardEventsRef = useRef(dashboardEvents);

    const changePeriod = (val: Period) => setPeriod(val);
    const changeFilters = (val: Array<EventsFilterPreset>) => setEventsFilters(val);

    const toggleDisableState = async (event: IStickerSentEvent) => {
        const updatedEvent = (await toggleStickerDisableState(event._id).unwrap()) as IStickerSentEvent;
        setDashboardEvents([...dashboardEvents].map(
            (e) => isStickerSentEvent(e) && e.stickerId === updatedEvent.stickerId
                ? { ...e, isStickerDisabled: updatedEvent.isStickerDisabled }
                : e,
        ));
    };

    const toggleMuteState = async (event: IStickerSentEvent) => {
        let muteValue: boolean;

        if (event.isUserMutedByStreamer) {
            await unmuteUser(event.userId);
            muteValue = false;
            notify(t('mute.user-is-unblocked'), NotificationType.SUCCESS);
        } else {
            await muteUser(event.userId);
            muteValue = true;
            notify(t('mute.user-is-blocked'), NotificationType.SUCCESS);
        }

        setDashboardEvents([...dashboardEvents].map(
            (e) => isStickerSentEvent(e) && e.userId === event.userId
                ? { ...e, isUserMutedByStreamer: muteValue }
                : e,
        ));
    };

    const onMessageHandler = (message: TWSMessage, callback: (data: unknown) => void = noop) => {
        const { type, data } = message;

        if (type === WSMessageType.EVENT_CREATED) {
            callback({ wasRead: true });
            const event = data.event;
            event.id = data.eventId ?? '';
            event._id = event.id;

            if (data.event.kind === EventKind.STICKER_SENT||
                data.event.kind === EventKind.MEME_CANNON_STICKER_SENT) {
                (event as IStickerRelatedEvent).stickerId = data.event.stickerId;
            }

            setDashboardEvents([event].concat(dashboardEventsRef.current));
        }
    };

    useEffect(() => {
        dashboardEventsRef.current = dashboardEvents;
    }, [dashboardEvents]);

    useEffect(() => {
        subscribe(currentUser?.id, onMessageHandler);

        return () => unsubscribe(currentUser?.id, onMessageHandler);
    }, [currentUser]);

    useEffect(() => {
        if (active && !isDashboardEventsFetching) {
            loadDashboardEvents({ limit: PAGE_SIZE, filters: eventsFilters }, false).unwrap().then((loadedDashboardEvents) => {
                setDashboardEvents(loadedDashboardEvents);
            });
        }
    }, [eventsFilters, active]);

    useEffect(() => {
        if (active) {
            loadDashboardData(period, false).unwrap().then((loadedDashboardData) => {
                setDashboardData(loadedDashboardData);
            });
        }
    }, [period, active]);

    return {
        dashboardEvents,
        dashboardData,
        isDashboardEventsFetching,
        changePeriod,
        changeFilters,
        toggleDisableState,
        toggleMuteState,
    };
};
