import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { ReactFitty } from 'react-fitty';

import { FillType, FontSize, ITitleStyle } from '@typings';
import { VISUAL_EFFECT_SPEED_MAP } from '@views/pages/StreamerSettings/components/AlertSettings/const';

import s from './Title.scss';
import { EAnimationState } from '../../enums';
import { getFillTextStyles } from '../../utils/getFillTextStyles';
import { getOutlinedTextStyles } from '../../utils/getOutlinedTextStyles';
import { renderTemplatedText } from '../../utils/renderTemplatedText';

interface ITitleProps {
    className?: string;
    vars: Record<string, string>;
    titleStyle: ITitleStyle;
    animationState?: EAnimationState;
}

const FONT_SIZE_MAP = {
    [FontSize.S]: 30,
    [FontSize.M]: 40,
    [FontSize.L]: 50,
};

export const Title = (props: ITitleProps) => {
    const {
        className,
        vars,
        titleStyle,
        animationState,
    } = props;
    const varsStyles = getFillTextStyles(titleStyle.variablesFill);
    const fillStyles = getFillTextStyles(titleStyle.fill);
    const outlinedStyles = getOutlinedTextStyles({
        stroke: titleStyle.stroke,
    });
    const duration = animationState === EAnimationState.NONE
        ? 0
        : (
            animationState === EAnimationState.IN
                ? VISUAL_EFFECT_SPEED_MAP[titleStyle.effects.in.speed]
                : VISUAL_EFFECT_SPEED_MAP[titleStyle.effects.out.speed]
        );
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        rootRef?.current?.style?.setProperty('--animate-duration', `${duration}s`);
    }, [duration]);

    return (
        <div
            className={cn(
                s.root,
                {
                    'animate__animated': animationState !== EAnimationState.NONE,
                    [`animate__${titleStyle.effects.in.effect}`]: animationState === EAnimationState.IN,
                    [`animate__${titleStyle.effects.out.effect}`]: animationState === EAnimationState.OUT,
                },
                className,
            )}
            style={{
                fontSize: FONT_SIZE_MAP[titleStyle.fontSize],
                fontFamily: `"${titleStyle.fontFamily}"`,
            }}
            ref={rootRef}
        >
            <div
                className={cn(s.text, { [s.textGradient]: titleStyle.fill.fillType === FillType.GRADIENT })}
                style={{
                    ...fillStyles,
                }}
            >
                <ReactFitty maxSize={50}>
                    {renderTemplatedText({
                        template: titleStyle.text,
                        vars,
                        varsStyles,
                        varsClassName: s.variable,
                    })}
                </ReactFitty>
            </div>

            <div
                className={s.stroke}
                style={{
                    ...outlinedStyles,
                }}
            >
                <ReactFitty maxSize={50}>
                    {renderTemplatedText({
                        template: titleStyle.text,
                        vars,
                        varsStyles,
                        varsClassName: s.variable,
                    })}
                </ReactFitty>
            </div>
        </div>
    );
};
