import { Select, ISelectProps } from '@hyperclap/ui';
import * as React from 'react';

import { Field, IBaseFieldProps } from '../Field';

interface IFieldSelectProps extends IBaseFieldProps, ISelectProps {}

export const FieldSelect = (props: IFieldSelectProps) => {
    const { label, ...selectProps } = props;

    return (
        <Field
            label={label}
            content={(
                <Select {...selectProps}/>
            )}
        />
    );
};
