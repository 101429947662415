import React from 'react';
import { useTranslation } from 'react-i18next';

import { AlertLayout } from '@typings';

import { IconHorizontal, IconVertical } from './assets';
import s from './LayoutForm.scss';
import { useAlertsSettingsType } from '../../../../../../../hooks';
import { IconRadioGroup } from '../../../../../../IconRadioGroup';
import { Field } from '../../Field';


interface ILayoutFormProps {
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

export const LayoutForm = (props: ILayoutFormProps) => {
    const {
        alertsSettingsType: {
            alertStyle: {
                alertStyle: {
                    layout,
                },
            },
            onLayoutChange,
        },
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.${id}`);

    return (
        <Field
            label={t('layout')}
            content={(
                <IconRadioGroup
                    classNames={{
                        item: s.item,
                        icon: s.icon,
                    }}
                    items={[
                        {
                            value: AlertLayout.HORIZONTAL,
                            title: t('horizontal'),
                            icon: IconHorizontal,
                            className: s.horizontal,
                        },
                        {
                            value: AlertLayout.VERTICAL,
                            title: t('horizontal'),
                            icon: IconVertical,
                            className: s.vertical,
                        },
                    ]}
                    value={layout}
                    onChange={onLayoutChange}
                />
            )}
        />
    );
};
