import { Button, Column, useLogger, useModals } from '@hyperclap/ui';
import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IconGoogleColorful, IconTwitchSolid, IconVkSolid } from '@assets/images/svg';
import { useApi } from '@hooks';

import s from './AuthButtons.scss';

interface AuthButtonsProps {
    className?: string;
    hideGoogle?: boolean;
    hideVK?: boolean;
    hideTwitch?: boolean;
    returnUrl?: string;
    callback?(): void;
}

let authWindow: Window | null;

export interface IMessage {
    action: string;
}

export const getAuthUrl = (service: string) => {
    return `${config?.hosts.apiHost}/auth/${service}?return_url=${encodeURIComponent(
        `${location.protocol}//${location.host}${'/auth/redirect'}`,
    )}`;
};

export const AuthButtons = (props: AuthButtonsProps) => {
    const logger = useLogger({ target: AuthButtons.name, showTimestamp: true });
    const dispatch = useDispatch();
    const {
        className,
        hideGoogle,
        hideVK,
        hideTwitch,
        returnUrl,
        callback,
    } = props;

    console.log(returnUrl);

    const {
        util,
    } = useApi();

    const {
        t,
    } = useTranslation();

    const { clearModals } = useModals();
    const navigate = useNavigate();

    const onAuthClick = async (service: string) => {
        authWindow = window.open(getAuthUrl(service));
    };

    const onMessage = (event: MessageEvent) => {
        try {
            const message = event.data as IMessage;

            if (message.action === 'banned') {
                authWindow?.close();
                clearModals();
                navigate(returnUrl ?? `/suspended`);
                dispatch(util.resetApiState());
            }

            if (message.action === 'close') {
                authWindow?.close();

                if (!callback) {
                    clearModals();
                }

                navigate(returnUrl ?? `/`);
                dispatch(util.resetApiState());

                if (callback) {
                    callback();
                }

                window.removeEventListener('message', onMessage);
            }
        } catch (error) {
            logger.error(error);
        }
    };

    useEffect(
        () => {
            window.addEventListener('message', onMessage);

            return () => {
                window.removeEventListener('message', onMessage);
            };
        },
        [],
    );

    return (
        <div className={className || s.authButtonsModal} onClick={(e) => e.stopPropagation()}>
            <Column rowGap={8} className={s.registrationButtonsBlock}>
                { !hideGoogle &&
                    <Button
                        className={cn(s.registrationButton, s.registrationButtonGoogle)}
                        caption={
                            <div className={s.registrationButtonCaption}>
                                <div className={s.registrationButtonIcon}><IconGoogleColorful/></div>
                                <div className={s.registrationButtonCaptionText}>{t('auth.google')}</div>
                            </div>
                        }
                        onClick={() => onAuthClick('google')}
                    />
                }

                { !hideTwitch &&
                    <Button
                        className={cn(s.registrationButton, s.registrationButtonTwitch)}
                        caption={
                            <div className={s.registrationButtonCaption}>
                                <div className={s.registrationButtonIcon}><IconTwitchSolid/></div>
                                <div className={s.registrationButtonCaptionText}>{t('auth.twitch')}</div>
                            </div>
                        }
                        onClick={() => onAuthClick('twitch')}
                    />
                }

                { !hideVK &&
                    <Button
                        className={cn(s.registrationButton, s.registrationButtonVk)}
                        caption={
                            <div className={s.registrationButtonCaption}>
                                <div className={s.registrationButtonIcon}><IconVkSolid/></div>
                                <div className={s.registrationButtonCaptionText}>{t('auth.vk')}</div>
                            </div>
                        }
                        onClick={() => onAuthClick('vk')}
                    />
                }
            </Column>
        </div>
    );
};
