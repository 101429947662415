import { Button, EComponentColor, Row, Select, TextArea } from '@hyperclap/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ISticker } from '@typings';

import s from './ControlPopupReport.scss';
import { useControlPopupReport } from './hooks';
import { ControlPopup } from '../ControlPopup';


interface IControlPopupReportProps {
    sticker: ISticker;
    onClose(): void;
}

export const ControlPopupReport = (props: IControlPopupReportProps) => {
    const {
        sticker,
        onClose,
    } = props;
    useControlPopupReport({
        sticker,
    });
    const { t: translation } = useTranslation();
    // const t = (id: string) => translation(`sticker-popup.controls.popups.report.${id}`);
    const tc = (id: string) => translation(`common.${id}`);

    const handleCloseClick = () => onClose();

    const handleDeleteClick = () => onClose();

    return (
        <ControlPopup
            className={s.root}
            onClose={onClose}
        >
            <Row>
                <Select
                    options={[]}
                />
            </Row>

            <Row>
                <TextArea/>
            </Row>

            <Row>
                <Button
                    caption={tc('cancel')}
                    color={EComponentColor.ACCENT}
                    disabled={true}
                    onClick={handleCloseClick}
                />
                <Button
                    caption={tc('delete')}
                    linkStyle={true}
                    onClick={handleDeleteClick}
                />
            </Row>
        </ControlPopup>
    );
};
