import { ERowAlignment, ISelectOption, Row, TextField } from '@hyperclap/ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FontSize, StrokeSize, TextPosition } from '@typings';

import { IconPositionBottom, IconPositionTop } from './assets';
import s from './TitleForm.scss';
import { EFontFamily } from '../../../../../../../enums';
import { useAlertsSettingsType } from '../../../../../../../hooks';
import { IconRadioGroup } from '../../../../../../IconRadioGroup';
import { Field } from '../../Field';
import { FieldColorPicker } from '../../FieldColorPicker';
import { FieldEffects } from '../../FieldEffects';
import { FieldSelect } from '../../FieldSelect';
import { FieldStroke } from '../../FieldStroke';
import { FieldSwitcher } from '../../FieldSwitcher';
import { FormRow } from '../../FormRow';
import { FormRowField } from '../../FormRowField';

interface ITitleFormProps {
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

export const TitleForm = (props: ITitleFormProps) => {
    const {
        alertsSettingsType: {
            alertStyle: {
                alertStyle: {
                    title: {
                        enabled,
                        text,
                        position,
                        fontFamily,
                        fontSize,
                        fill,
                        variablesFill,
                        stroke,
                        effects,
                    },
                },
            },
            fontFamilyList,
            fontSizeList,
            strokeSizeList,
            visualEffectInList,
            visualEffectOutList,
            visualEffectSpeedList,
            onTitleEnabledChange,
            onTitleTextChange,
            onTitlePositionChange,
            onTitleFontFamilyChange,
            onTitleFontSizeChange,
            onTitleFillChange,
            onTitleVariablesFillChange,
            onTitleStrokeSizeChange,
            onTitleStrokeFillChange,
            onTitleEffectsChange,
        },
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.forms.${id}`);

    const handleFontFamilyChange = (selection?: ISelectOption) => {
        onTitleFontFamilyChange(selection?.value as EFontFamily);
    };

    const handleFontSizeChange = (selection?: ISelectOption) => {
        onTitleFontSizeChange(selection?.value as FontSize);
    };

    const handleStrokeSizeChange = (selection?: ISelectOption) => {
        onTitleStrokeSizeChange(selection?.value as StrokeSize);
    };

    return (
        <div>
            <FormRow>
                <FieldSwitcher
                    label={t('display')}
                    checked={enabled}
                    onChange={onTitleEnabledChange}
                />
            </FormRow>

            <FormRow>
                <Field
                    label={t('position')}
                    content={
                        <IconRadioGroup
                            classNames={{
                                item: s.positionItem,
                            }}
                            items={[
                                {
                                    value: TextPosition.TOP,
                                    title: t('textPosition.top'),
                                    icon: IconPositionTop,
                                },
                                {
                                    value: TextPosition.BOTTOM,
                                    title: t('textPosition.bottom'),
                                    icon: IconPositionBottom,
                                },
                            ]}
                            value={position}
                            onChange={onTitlePositionChange}
                        />
                    }
                />
            </FormRow>

            <FormRow>
                <Field
                    className={s.text}
                    label={t('text')}
                    content={(
                        <TextField
                            defaultValue={text}
                            onChanged={onTitleTextChange}
                        />
                    )}
                />
            </FormRow>

            <FormRow>
                <FormRowField>
                    <FieldSelect
                        className={s.fontFamily}
                        label={t('font')}
                        options={fontFamilyList.map((item) => ({
                            caption: item.title,
                            value: item.value,
                            selected: fontFamily === item.value,
                        }))}
                        onSelectionChanged={handleFontFamilyChange}
                    />
                </FormRowField>

                <FormRowField>
                    <Row alignment={ERowAlignment.BOTTOM}>
                        <FieldSelect
                            className={s.sizeSelect}
                            classNameControlInput={s.sizeSelectControlInput}
                            label={t('size')}
                            options={fontSizeList.map((item) => ({
                                caption: item.title,
                                value: item.value,
                                selected: fontSize === item.value,
                            }))}
                            onSelectionChanged={handleFontSizeChange}
                        />

                        <FieldColorPicker
                            className={s.colorPicker}
                            value={fill}
                            caption={t('color')}
                            onChange={onTitleFillChange}
                        />

                        <FieldColorPicker
                            className={s.colorPicker}
                            value={variablesFill}
                            caption={t('highlight')}
                            onChange={onTitleVariablesFillChange}
                        />
                    </Row>
                </FormRowField>
            </FormRow>

            <FormRow>
                <FieldStroke
                    className={s.stroke}
                    classNameSelect={s.strokeSelect}
                    classNameControlInput={s.strokeSelectControlInput}
                    options={strokeSizeList.map((item) => ({
                        caption: item.title,
                        value: item.value,
                        selected: stroke.size === item.value,
                    }))}
                    fill={stroke.fill}
                    isFlatOnly={true}
                    onSelectionChanged={handleStrokeSizeChange}
                    onFillChange={onTitleStrokeFillChange}
                />

                <FieldSelect
                    className={s.fade}
                    classNameControlInput={s.fadeControlInput}
                    label={t('fade')}
                    options={[]}
                />

                <FieldColorPicker
                    className={s.colorPicker}
                />
            </FormRow>

            <FormRow>
                <FieldEffects
                    visualEffectInList={visualEffectInList}
                    visualEffectOutList={visualEffectOutList}
                    visualEffectSpeedList={visualEffectSpeedList}
                    value={effects}
                    onChange={onTitleEffectsChange}
                />
            </FormRow>
        </div>
    );
};
