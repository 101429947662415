import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line import/no-unresolved
import config from 'config';

import { AUTH_TOKEN_NAME, CSRF_COOKIE_NAME, CSRF_HEADER_NAME, getCookie } from '@common';

export const baseApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: config?.hosts.ttsProxyApiHost,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(AUTH_TOKEN_NAME);
            const csrf = getCookie(CSRF_COOKIE_NAME);
            headers.set('Authorization', `Bearer ${token}`);

            if (csrf) {
                headers.set(CSRF_HEADER_NAME, csrf);
            }
        },
    }),
    endpoints: () => ({}),
    reducerPath: 'tts/web-api',
    tagTypes: [],
});
