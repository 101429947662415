import { Row, Column, ISelectOption } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { IVisualEffects, VisualEffect, VisualEffectSpeed } from '@typings';

import s from './FieldEffects.scss';
import { FormRow } from '../../components/FormRow';
import { Field, IBaseFieldProps } from '../Field';
import { FieldSelect } from '../FieldSelect';


interface IFieldEffectsProps extends IBaseFieldProps {
    visualEffectInList: { value: VisualEffect, title: string }[],
    visualEffectOutList: { value: VisualEffect, title: string }[],
    visualEffectSpeedList: { value: VisualEffectSpeed, title: string }[],
    value: IVisualEffects;
    onChange?(value: IVisualEffects): void;
}

export const FieldEffects = (props: IFieldEffectsProps) => {
    const {
        visualEffectInList,
        visualEffectOutList,
        visualEffectSpeedList,
        value,
        onChange = noop,
    } = props;

    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.forms.${id}`);

    const handleChange = (effects: Partial<IVisualEffects>) => {
        onChange({
            ...value,
            ...effects,
        });
    };

    const handleInEffectChange = (selection?: ISelectOption) => {
        handleChange({
            in: {
                ...value.in,
                effect: selection?.value as VisualEffect,
            },
        });
    };

    const handleInSpeedChange = (selection?: ISelectOption) => {
        handleChange({
            in: {
                ...value.in,
                speed: selection?.value as VisualEffectSpeed,
            },
        });
    };

    const handleOutEffectChange = (selection?: ISelectOption) => {
        handleChange({
            out: {
                ...value.out,
                effect: selection?.value as VisualEffect,
            },
        });
    };

    const handleOutSpeedChange = (selection?: ISelectOption) => {
        handleChange({
            out: {
                ...value.out,
                speed: selection?.value as VisualEffectSpeed,
            },
        });
    };

    return (
        <Column>
            <FormRow>
                <Field
                    className={s.buildIn}
                    label={t('buildIn')}
                    content={(
                        <Row>
                            <FieldSelect
                                className={s.effect}
                                options={visualEffectInList.map((item) => ({
                                    caption: item.title,
                                    value: item.value,
                                    selected: value.in.effect === item.value,
                                }))}
                                onSelectionChanged={handleInEffectChange}
                            />

                            <FieldSelect
                                className={s.speed}
                                options={visualEffectSpeedList.map((item) => ({
                                    caption: item.title,
                                    value: item.value,
                                    selected: value.in.speed === item.value,
                                }))}
                                onSelectionChanged={handleInSpeedChange}
                            />
                        </Row>
                    )}
                />
            </FormRow>

            <FormRow>
                <Field
                    label={t('buildOut')}
                    content={(
                        <Row>
                            <FieldSelect
                                className={s.effect}
                                options={visualEffectOutList.map((item) => ({
                                    caption: item.title,
                                    value: item.value,
                                    selected: value.out.effect === item.value,
                                }))}
                                onSelectionChanged={handleOutEffectChange}
                            />

                            <FieldSelect
                                className={s.speed}
                                options={visualEffectSpeedList.map((item) => ({
                                    caption: item.title,
                                    value: item.value,
                                    selected: value.out.speed === item.value,
                                }))}
                                onSelectionChanged={handleOutSpeedChange}
                            />
                        </Row>
                    )}
                />
            </FormRow>
        </Column>
    );
};
