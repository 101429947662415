import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IconCheckCircleLine,
    IconCheckCircleSolid,
    IconHeartLine,
    IconHeartSolid,
} from '@assets/images/svg';
import { ISticker, IUser } from '@typings';

import s from './ControlsStreamer.scss';
import { useControlsStreamer } from './hooks';

interface IControlsStreamerProps {
    sticker: ISticker;
    currentUser?: IUser;
    updateSticker(stickerData: ISticker): void;
}

export const ControlsStreamer = (props: IControlsStreamerProps) => {
    const {
        sticker,
        currentUser,
        updateSticker,
    } = props;
    const {
        onFavoritesClick,
        onChannelClick,
    } = useControlsStreamer({
        sticker,
        currentUser,
        updateSticker,
    });

    const { t: translation } = useTranslation();

    const t = (id: string) => translation(`sticker-popup.controls.streamer.${id}`);

    return (
        <div className={s.root}>
            <div
                className={s.control}
                onClick={onFavoritesClick}
            >
                {sticker.isFavorite
                    ? (
                        <>
                            <IconHeartSolid
                                className={
                                    cn(
                                        s.controlIcon,
                                        s.controlIconHeart,
                                        s.controlIconHeartActive,
                                    )
                                }
                            />

                            <span className={s.controlText}>
                                {sticker.likedCount?.toString()}
                            </span>
                        </>
                    )
                    : (
                        <>
                            <IconHeartLine
                                className={
                                    cn(
                                        s.controlIcon,
                                        s.controlIconHeart,
                                    )
                                }
                            />

                            <span className={s.controlText}>
                                {sticker.likedCount ? sticker.likedCount : t('addToFavorites')}
                            </span>
                        </>
                    )
                }
            </div>

            <div
                className={s.control}
                onClick={onChannelClick}
            >
                {sticker.isAddedToChannel
                    ? (
                        <>
                            <IconCheckCircleSolid
                                className={cn(s.controlIcon, s.controlIconCheckActive)}
                            />

                            <span className={s.controlText}>
                                {t('addedToChannel')}
                            </span>
                        </>
                    )
                    : (
                        <>
                            <IconCheckCircleLine
                                className={cn(s.controlIcon)}
                            />

                            <span className={s.controlText}>
                                {t('addToChannel')}
                            </span>
                        </>
                    )
                }
            </div>
        </div>
    );
};
