// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FieldEffects__buildIn--_QAvS {
  margin-right: 15px;
}

.FieldEffects__effect--xNACn {
  width: 200px;
  margin-right: 5px;
}

.FieldEffects__speed--U_iXI {
  width: 145px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Elements/components/FormSwitcher/components/FieldEffects/FieldEffects.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".buildIn {\r\n    margin-right: 15px;\r\n}\r\n\r\n.effect {\r\n    width: 200px;\r\n    margin-right: 5px;\r\n}\r\n\r\n.speed {\r\n    width: 145px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buildIn": `FieldEffects__buildIn--_QAvS`,
	"effect": `FieldEffects__effect--xNACn`,
	"speed": `FieldEffects__speed--U_iXI`
};
export default ___CSS_LOADER_EXPORT___;
