import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconCogLine } from '@assets/images/svg';
import { ISticker, IUser } from '@typings';

import { ControlItem } from '../ControlItem';
import { ControlPopupSettings } from '../ControlPopupSettings';


interface IControlSettingsProps {
    currentUser?: IUser;
    sticker: ISticker;
    isPopupDisplayed: boolean;
    updateSticker(stickerData: ISticker): void;
    onClick(): void;
    onClose(): void;
}

export const ControlSettings = (props: IControlSettingsProps) => {
    const {
        sticker,
        currentUser,
        isPopupDisplayed,
        updateSticker,
        onClick,
        onClose,
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.controls.${id}`);

    return (
        <ControlItem
            title={t('settings')}
            icon={<IconCogLine/>}
            onClick={onClick}
        >
            {isPopupDisplayed && (
                <ControlPopupSettings
                    currentUser={currentUser}
                    sticker={sticker}
                    updateSticker={updateSticker}
                    onClose={onClose}
                />
            )}
        </ControlItem>
    );
};
