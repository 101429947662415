/* eslint-disable sonarjs/no-duplicate-string */
import {
    Button,
    Column,
    Divider,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    Hint,
    Label,
    NumericField,
    Row,
    Select,
    SelectOptionValueType,
    Slider,
    Switch,
} from '@hyperclap/ui';
import cn from 'classnames';
import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { IconSoundActiveLine } from '@assets/images/svg';
import {
    castToArray,
    currencyValueText,
    declensionUserCurrency,
    DEFAULT_SEND_DELAY,
    DEFAULT_SOUND_VOLUME,
    DEFAULT_STICKER_PRICE,
} from '@common';
import { useAppContext } from '@hooks';
import { StickerSize } from '@typings';
import { Grid } from '@views';

import s from './StickersSettings.scss';

const MIN_PRICE = 1;
const MAX_PRICE = 1000;
const EXCHANGE_RATE = 10;

const DEFAULT_SEND_DELAY_SETTING_NAME = 'channel.stickers.defaultSendDelay';

export const StickersSettings = () => {
    const app = useAppContext();
    const {
        currentUser: {
            currentUser,
        },
        settings: {
            streamer: {
                page: {
                    updateChannel,
                },
            },
            system,
        },
        translation: {
            t,
        },
    } = app;

    const { isLoaded: settingsAreLoaded } = system;

    const testAudioRef = useRef<HTMLAudioElement>(null);

    const [disableStickers, setDisableStickers] = useState(currentUser?.channel.disableStickers ?? false);
    const [onlyChannelStickers, setOnlyChannelStickers] = useState<boolean>();
    const [isThematicSetsEnabled, setIsThematicSetsEnabled] =
        useState(currentUser?.channel.isThematicSetsEnabled ?? true);
    const [isStickersMessagesEnabled, setIsStickersMessagesEnabled] =
        useState(currentUser?.channel?.isStickersMessagesEnabled ?? true);
    const [isSoundOnlyStickersEnabled, setIsSoundOnlyStickersEnabled] =
        useState(currentUser?.channel?.isSoundOnlyStickersEnabled ?? true);

    const [catalogueStickerPrice, setCatalogueStickerPrice] = useState(0);
    const [fullscreenStickersEnabled, setFullscreenStickersEnabled] =
        useState<boolean>(currentUser?.channel?.fullscreenStickersEnabled ?? true);
    const [fullscreenStickersPrice, setFullscreenStickersPrice] = useState(0);
    const [memeCannonEnabled, setMemeCannonEnabled] = useState<boolean>(currentUser?.channel?.memeCannonEnabled ?? true);
    const [memeCannonPrice, setMemeCannonPrice] = useState(0);
    const [stickerSize, setStickerSize] = useState<string>(StickerSize.MEDIUM);
    const [stickerStickAreasMask, setStickerStickAreasMask] = useState(511);
    const [soundVolume, setSoundVolume] = useState(DEFAULT_SOUND_VOLUME);
    const [sendDelay, setSendDelay] = useState(1000);

    const [isSavePriceEnabled, setIsSavePriceEnabled] = useState(false);

    const onSaveDataClick = () => {
        void updateChannel({
            disableStickers,
            onlyChannelStickers,
            isThematicSetsEnabled,
            isStickersMessagesEnabled,
            isSoundOnlyStickersEnabled,
        });
    };

    const onSavePriceDataClick = () => {
        void updateChannel({
            catalogueStickerPrice: Number(catalogueStickerPrice),
            fullscreenStickersEnabled,
            fullscreenStickersPrice: Number(fullscreenStickersPrice),
            memeCannonEnabled,
            memeCannonPrice: Number(memeCannonPrice),
        });
    };

    const onSaveSizeAndPosition = () => {
        void updateChannel({
            stickerSize,
            stickerStickAreasMask,
            soundVolume,
            sendDelay,
        });
    };

    const onSoundVolumeTestClick = () => {
        if (testAudioRef.current) {
            testAudioRef.current.volume = soundVolume / 100;
            testAudioRef.current.pause();
            testAudioRef.current.currentTime = 0;
            testAudioRef.current.play();
        }
    };

    const onChangeSendDelay = (value?: SelectOptionValueType) => {
        setSendDelay(Number(value));
    };

    useEffect(() => {
        if (currentUser?.channel) {
            setDisableStickers(currentUser.channel?.disableStickers ?? false);
            setOnlyChannelStickers(currentUser.channel.onlyChannelStickers ?? false);
            setIsThematicSetsEnabled(currentUser.channel.isThematicSetsEnabled ?? false);
            setIsStickersMessagesEnabled(currentUser.channel.isStickersMessagesEnabled ?? false);
            setIsSoundOnlyStickersEnabled(currentUser.channel.isSoundOnlyStickersEnabled ?? false);

            setStickerSize(currentUser.channel?.stickerSize ?? StickerSize.MEDIUM);
            setStickerStickAreasMask(currentUser.channel?.stickerStickAreasMask ?? 511);
            setSoundVolume(currentUser.channel?.soundVolume ?? DEFAULT_SOUND_VOLUME);
            setSendDelay(currentUser.channel?.sendDelay ?? system.asInt(DEFAULT_SEND_DELAY_SETTING_NAME) ?? DEFAULT_SEND_DELAY);

            setCatalogueStickerPrice(currentUser.channel?.catalogueStickerPrice ?? DEFAULT_STICKER_PRICE);
            setFullscreenStickersPrice(currentUser.channel?.fullscreenStickersPrice ?? DEFAULT_STICKER_PRICE);
            setFullscreenStickersEnabled(currentUser.channel?.fullscreenStickersEnabled ?? true);
            setMemeCannonPrice(currentUser.channel?.memeCannonPrice ?? DEFAULT_STICKER_PRICE);
            setMemeCannonEnabled(currentUser.channel?.memeCannonEnabled ?? true);
        }
    }, [currentUser, settingsAreLoaded]);

    useEffect(() => {
        setIsSavePriceEnabled(!!catalogueStickerPrice && catalogueStickerPrice >= MIN_PRICE && catalogueStickerPrice <= MAX_PRICE &&
            !!fullscreenStickersPrice && fullscreenStickersPrice >= MIN_PRICE && fullscreenStickersPrice <= MAX_PRICE &&
            !!memeCannonPrice && memeCannonPrice >= MIN_PRICE && memeCannonPrice <= MAX_PRICE);
    }, [catalogueStickerPrice, fullscreenStickersPrice, memeCannonPrice]);

    const currencyNameDeclensions = currentUser?.channel.currencyNameDeclensions;
    const mPrepositional = currencyNameDeclensions?.multiple.prepositional;

    return (
        <Row columnGap={20} margin={{ top: 15, bottom: 50 }} alignment={ERowAlignment.TOP}>
            <Column width={510}>
                <Label padding={{ bottom: 10 }} caption={t('streamer-settings.stickers.stickers-section')} size={EComponentSize.LARGE}/>

                <Column margin={{ bottom: 20 }}>
                    <Row>
                        <Label caption={t('streamer-settings.stickers.enable-stickers')} width={250} size={EComponentSize.MEDIUM}/>
                        <Switch
                            checked={!currentUser?.channel?.disableStickers}
                            color={EComponentColor.ACCENT}
                            onChange={(checked) => setDisableStickers(!checked)}
                        />
                    </Row>
                    <Hint muted textSize={EComponentSize.SMALL}>
                        {t('streamer-settings.stickers.enable-stickers-description')}
                    </Hint>
                </Column>

                <Column margin={{ bottom: 10 }}>
                    <Row>
                        <Label caption={t('streamer-settings.stickers.only-channel-stickers')} width={250} size={EComponentSize.MEDIUM}/>
                        <Switch
                            checked={currentUser?.channel?.onlyChannelStickers}
                            color={EComponentColor.ACCENT}
                            onChange={setOnlyChannelStickers}
                        />
                    </Row>
                    <Hint muted textSize={EComponentSize.SMALL}>
                        {t('streamer-settings.stickers.only-channel-stickers-description')}
                    </Hint>
                </Column>

                <Column margin={{ bottom: 10 }}>
                    <Row>
                        <Label caption={t('streamer-settings.stickers.thematic-sets')} width={250} size={EComponentSize.MEDIUM}/>
                        <Switch
                            checked={currentUser?.channel?.isThematicSetsEnabled}
                            color={EComponentColor.ACCENT}
                            onChange={setIsThematicSetsEnabled}
                        />
                    </Row>
                    <Hint muted textSize={EComponentSize.SMALL}>
                        {t('streamer-settings.stickers.thematic-sets-description')}
                    </Hint>
                </Column>

                <Column margin={{ bottom: 10 }}>
                    <Row>
                        <Label caption={t('streamer-settings.stickers.stickers-messages')} width={250} size={EComponentSize.MEDIUM}/>
                        <Switch
                            checked={currentUser?.channel?.isStickersMessagesEnabled ?? true}
                            color={EComponentColor.ACCENT}
                            onChange={setIsStickersMessagesEnabled}
                        />
                    </Row>
                    <Hint muted textSize={EComponentSize.SMALL}>
                        {t('streamer-settings.stickers.stickers-messages-description')}
                    </Hint>
                </Column>

                <Column margin={{ bottom: 10 }}>
                    <Row>
                        <Label caption={t('streamer-settings.stickers.sound-only-stickers')} width={250} size={EComponentSize.MEDIUM}/>
                        <Switch
                            checked={currentUser?.channel?.isSoundOnlyStickersEnabled ?? true}
                            color={EComponentColor.ACCENT}
                            onChange={setIsSoundOnlyStickersEnabled}
                        />
                    </Row>
                    <Hint muted textSize={EComponentSize.SMALL}>
                        {t('streamer-settings.stickers.sound-only-stickers-description')}
                    </Hint>
                </Column>

                <Column margin={{ bottom: 10 }}>
                    <Row padding={{ top: 20 }}>
                        <Button
                            caption={t('common.save')}
                            className={s.stickersConfigButton}
                            color={EComponentColor.ACCENT}
                            width={160}
                            onClick={onSaveDataClick}
                        />
                    </Row>
                </Column>

                <Divider margin={{ top: 20, bottom: 20 }} width={510}/>

                <Label
                    padding={{ top: 0, bottom: 20 }}
                    caption={t('streamer-settings.stickers.prices-section')}
                    size={EComponentSize.LARGE}
                />

                <Column margin={{ bottom: 20 }}>
                    <NumericField
                        caption={t('streamer-settings.stickers.sticker-price', { currency: mPrepositional })}
                        min={MIN_PRICE}
                        max={MAX_PRICE}
                        defaultValue={currentUser?.channel.catalogueStickerPrice ?? 1}
                        width={270}
                        errorMessage={
                            !catalogueStickerPrice || catalogueStickerPrice < 1
                                ? t('streamer-settings.stickers.minimum-send-price', {
                                    price: `${MIN_PRICE} ${declensionUserCurrency(MIN_PRICE, currentUser)}`,
                                })
                                : catalogueStickerPrice > MAX_PRICE
                                    ? t('streamer-settings.stickers.maximum-send-price', {
                                        price: `${MAX_PRICE} ${declensionUserCurrency(MAX_PRICE, currentUser)}`,
                                    })
                                    : ''
                        }
                        hasError={!catalogueStickerPrice || catalogueStickerPrice < MIN_PRICE || catalogueStickerPrice > MAX_PRICE}
                        actions={[{ text: declensionUserCurrency(Number(catalogueStickerPrice), currentUser), noInteraction: true }]}
                        onChanged={(val) => setCatalogueStickerPrice(Number(val))}
                    />
                    <Hint muted margin={{ top: 5 }} textSize={EComponentSize.SMALL}>
                        {t('streamer-settings.stickers.sticker-price-description', {
                            sum: currencyValueText(EXCHANGE_RATE * Number(catalogueStickerPrice), i18next.language),
                        })}
                    </Hint>
                </Column>

                <Row margin={{ bottom: 20 }}>
                    <Column>
                        <Row margin={{ bottom: 10 }}>
                            <Label
                                padding={{ top: 0, bottom: 0 }}
                                caption={t('streamer-settings.stickers.fullscreen-price')}
                                size={EComponentSize.MEDIUM}
                                width={220}
                            />
                            <Switch
                                checked={currentUser?.channel?.fullscreenStickersEnabled ?? true}
                                color={EComponentColor.ACCENT}
                                onChange={setFullscreenStickersEnabled}
                            />
                        </Row>
                        { fullscreenStickersEnabled &&
                            <Row>
                                <Column>
                                    <NumericField
                                        min={MIN_PRICE}
                                        max={MAX_PRICE}
                                        defaultValue={currentUser?.channel?.fullscreenStickersPrice ?? 10}
                                        actions={[
                                            {
                                                text: declensionUserCurrency(
                                                    Number(fullscreenStickersPrice),
                                                    currentUser,
                                                ),
                                                noInteraction: true,
                                            },
                                        ]}
                                        width={270}
                                        errorMessage={
                                            !fullscreenStickersPrice || fullscreenStickersPrice < 1
                                                ? t('streamer-settings.stickers.minimum-send-price', {
                                                    price: `${MIN_PRICE} ${declensionUserCurrency(MIN_PRICE, currentUser)}`,
                                                })
                                                : fullscreenStickersPrice > MAX_PRICE
                                                    ? t('streamer-settings.stickers.maximum-send-price', {
                                                        price: `${MAX_PRICE} ${declensionUserCurrency(MAX_PRICE, currentUser)}`,
                                                    })
                                                    : ''
                                        }
                                        hasError={
                                            !fullscreenStickersPrice ||
                                            fullscreenStickersPrice < MIN_PRICE ||
                                            fullscreenStickersPrice > MAX_PRICE
                                        }
                                        onChanged={(val) => setFullscreenStickersPrice(val)}
                                    />
                                    <Hint muted margin={{ top: 5 }} textSize={EComponentSize.SMALL}>
                                        {t('streamer-settings.stickers.sticker-price-description', {
                                            sum: currencyValueText(EXCHANGE_RATE * Number(fullscreenStickersPrice), i18next.language),
                                        })}
                                    </Hint>
                                </Column>
                            </Row>
                        }
                    </Column>
                </Row>

                <Row margin={{ bottom: 20 }}>
                    <Column>
                        <Row margin={{ bottom: 10 }}>
                            <Label
                                padding={{ top: 0, bottom: 0 }}
                                caption={t('streamer-settings.stickers.meme-cannon-price')}
                                size={EComponentSize.MEDIUM}
                                width={220}
                            />
                            <Switch
                                checked={currentUser?.channel?.memeCannonEnabled ?? true}
                                color={EComponentColor.ACCENT}
                                onChange={setMemeCannonEnabled}
                            />
                        </Row>
                        { memeCannonEnabled &&
                            <Row>
                                <Column>
                                    <NumericField
                                        min={MIN_PRICE}
                                        max={MAX_PRICE}
                                        defaultValue={currentUser?.channel?.memeCannonPrice ?? 50}
                                        actions={[
                                            {
                                                text: declensionUserCurrency(Number(memeCannonPrice), currentUser),
                                                noInteraction: true,
                                            },
                                        ]}
                                        width={270}
                                        errorMessage={
                                            !memeCannonPrice || memeCannonPrice < 1
                                                ? t('streamer-settings.stickers.minimum-send-price', {
                                                    price: `${MIN_PRICE} ${declensionUserCurrency(MIN_PRICE, currentUser)}`,
                                                })
                                                : memeCannonPrice > MAX_PRICE
                                                    ? t('streamer-settings.stickers.maximum-send-price', {
                                                        price: `${MAX_PRICE} ${declensionUserCurrency(MAX_PRICE, currentUser)}`,
                                                    })
                                                    : ''
                                        }
                                        hasError={!memeCannonPrice || memeCannonPrice < MIN_PRICE || memeCannonPrice > MAX_PRICE}
                                        onChanged={(val) => setMemeCannonPrice(val)}
                                    />
                                    <Hint muted margin={{ top: 5 }} textSize={EComponentSize.SMALL}>
                                        {t('streamer-settings.stickers.sticker-price-description', {
                                            sum: currencyValueText(EXCHANGE_RATE * Number(memeCannonPrice), i18next.language),
                                        })}
                                    </Hint>
                                </Column>
                            </Row>
                        }
                    </Column>
                </Row>

                <Row margin={{ bottom: 20 }}>
                    <Button
                        caption={t('common.save')}
                        className={s.stickersConfigButton}
                        color={EComponentColor.ACCENT}
                        disabled={!isSavePriceEnabled}
                        width={160}
                        onClick={onSavePriceDataClick}
                    />
                </Row>

                <Divider margin={{ top: 10, bottom: 10 }} width={510}/>

                <Label
                    padding={{ bottom: 10 }}
                    caption={t('streamer-settings.stickers.display-section')}
                    size={EComponentSize.LARGE}
                />

                <Row margin={{ bottom: 20 }}>
                    <Column>
                        <Label caption={t('streamer-settings.stickers.stickers-positioning')} size={EComponentSize.MEDIUM} />
                        <Grid
                            mask={stickerStickAreasMask}
                            multiselect={true}
                            onSelectionChange={setStickerStickAreasMask}
                        />
                    </Column>
                </Row>

                <Row margin={{ bottom: 20 }}>
                    <Column grow>
                        <Label caption={t('streamer-settings.stickers.stickers-size')} size={EComponentSize.MEDIUM} />
                        <Row columnGap={9}>
                            <Button
                                caption='S'
                                className={cn(s.sizeButton, s.sizeButtonSmall, { [s.activeButton]: stickerSize === StickerSize.XSMALL })}
                                width={92}
                                onClick={() => setStickerSize(StickerSize.XSMALL)}
                            />
                            <Button
                                caption='M'
                                className={cn(s.sizeButton, s.sizeButtonSmall, { [s.activeButton]: stickerSize === StickerSize.SMALL })}
                                width={92}
                                onClick={() => setStickerSize(StickerSize.SMALL)}
                            />
                            <Button
                                caption='L'
                                className={cn(s.sizeButton, s.sizeButtonMedium, { [s.activeButton]: stickerSize === StickerSize.MEDIUM })}
                                width={92}
                                onClick={() => setStickerSize(StickerSize.MEDIUM)}
                            />
                            <Button
                                caption='XL'
                                className={cn(s.sizeButton, s.sizeButtonLarge, { [s.activeButton]: stickerSize === StickerSize.LARGE })}
                                width={92}
                                onClick={() => setStickerSize(StickerSize.LARGE)}
                            />
                        </Row>
                    </Column>
                </Row>

                <Row columnGap={10} margin={{ bottom: 20 }}>
                    <Slider
                        caption={t('streamer-settings.stickers.sound-volume')}
                        className={s.volumeSlider}
                        showPosition
                        minValue={0}
                        maxValue={100}
                        initialValue={currentUser?.channel?.soundVolume ?? DEFAULT_SOUND_VOLUME}
                        realtimeUpdate
                        onChange={(val) => setSoundVolume(val)}
                    />
                    <Button
                        icon={<IconSoundActiveLine/>}
                        className={s.soundVolumeTest}
                        onClick={() => onSoundVolumeTestClick()}
                    />
                    <audio src={'/assets/sounds/meme-de-creditos-finales.mp3'} ref={testAudioRef} />
                </Row>

                <Row>
                    <Label padding={{ top: 0 }} caption={t('streamer-settings.stickers.sending-delay')} size={EComponentSize.MEDIUM}/>
                </Row>

                <Row margin={{ bottom: 20 }}>
                    <Select
                        className={s.selector}
                        options={[
                            { order: 0, value: 0, caption: `${t('common.no-delay')}`, selected: sendDelay === 0 },
                            { order: 1, value: 1000, caption: `1 ${t('common.second')}`, selected: sendDelay === 1000 },
                            { order: 2, value: 3000, caption: `3 ${t('common.seconds')}`, selected: sendDelay === 3000 },
                            { order: 3, value: 5000, caption: `5 ${t('common.seconds-alt')}`, selected: sendDelay === 5000 },
                            { order: 4, value: 10000, caption: `10 ${t('common.seconds-alt')}`, selected: sendDelay === 10000 },
                            { order: 5, value: 15000, caption: `15 ${t('common.seconds-alt')}`, selected: sendDelay === 15000 },
                        ]}
                        onValueChanged={onChangeSendDelay}
                    />
                </Row>
                <Row>
                    <Button
                        caption={t('common.save')}
                        className={s.stickersConfigButton}
                        color={EComponentColor.ACCENT}
                        width={160}
                        onClick={onSaveSizeAndPosition}
                    />
                </Row>
            </Column>
            <Column padding={{ top: 10 }}>
                <Hint grow muted textSize={EComponentSize.SMALL}>
                    <div>
                        {castToArray<string>(t('streamer-settings.stickers.hints.settings-advices', { returnObjects: true }))
                            .map((element, idx) => (
                                <div key={idx}>
                                    <Trans i18nKey={element} components={[<a key={idx}/>]}>
                                        {element}
                                    </Trans>
                                </div>
                            ))
                        }
                    </div>
                </Hint>
            </Column>
        </Row>
    );
};
