import { IVoicesCategory } from '@typings';

import { baseApi } from './base.api';

export const voiceCategoryApi = baseApi
    .enhanceEndpoints({
        addTagTypes: ['Settings_VoicesCategoriesList'],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadVoiceCategories: build.query<Array<IVoicesCategory>, void>({
                query: () => ({
                    url: 'settings/voices-categories',
                    method: 'GET',
                }),
                providesTags: ['Settings_VoicesCategoriesList'],
            }),
        }),
    });
