import { use as i18next } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '@assets/translations/en.translation.json';
import ru from '@assets/translations/ru.translation.json';
import { getCookie, ONE_YEAR, setCookie } from '@common';
import appSpecificEn from '@memealerts/assets/translations/en.translation.json';
import appSpecificRu from '@memealerts/assets/translations/ru.translation.json';
import { DEFAULT_SYSTEM_LANGUAGE, DEFAULT_SYSTEM_LANGUAGES, LANGUAGE_COOKIE_NAME, TLanguageShortName } from '@typings';

const resources = {
    en: {
        translation: en,
        appSpecific: appSpecificEn,
    },
    ru: {
        translation: ru,
        appSpecific: appSpecificRu,
    },
};

let userSelectedLanguage = getCookie(LANGUAGE_COOKIE_NAME) || DEFAULT_SYSTEM_LANGUAGE.shortName;

if (!DEFAULT_SYSTEM_LANGUAGES.map((l) => l.shortName).includes(userSelectedLanguage as TLanguageShortName)) {
    userSelectedLanguage = DEFAULT_SYSTEM_LANGUAGE.shortName;
    setCookie(LANGUAGE_COOKIE_NAME, userSelectedLanguage, { maxAge: ONE_YEAR });
}

void i18next(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        ns: ['translation', 'appSpecific'],
        defaultNS: 'translation',
        lng: userSelectedLanguage || 'ru',
    });
