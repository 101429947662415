import cn from 'classnames';
import React from 'react';

import { IconCloseLine } from '@assets/images/svg';

import s from './ControlPopup.scss';

interface IControlPopupProps {
    className?: string;
    onClose(): void;
}

export const ControlPopup = (props: React.PropsWithChildren<IControlPopupProps>) => {
    const {
        className,
        onClose,
    } = props;

    const handleClick = (event: React.MouseEvent) => event.stopPropagation();

    return (
        <div className={cn(s.root, className)} onClick={handleClick}>
            <IconCloseLine className={s.close} onClick={onClose}/>
            {props.children}
        </div>
    );
};
