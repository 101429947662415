// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Preset__root--iwdEt {
  padding-bottom: 10px;
}

.Preset__select--dsIGv {
  width: 280px;
  margin-right: 15px;
}

.Preset__button--ZN05A {
  margin-right: 10px;
}
.Preset__button--ZN05A:last-child {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Preset/Preset.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;;AAEA;EACI,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;AACI;EACI,eAAA;AACR","sourcesContent":[".root {\r\n    padding-bottom: 10px;\r\n}\r\n\r\n.select {\r\n    width: 280px;\r\n    margin-right: 15px;\r\n}\r\n\r\n.button {\r\n    margin-right: 10px;\r\n\r\n    &:last-child {\r\n        margin-right: 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Preset__root--iwdEt`,
	"select": `Preset__select--dsIGv`,
	"button": `Preset__button--ZN05A`
};
export default ___CSS_LOADER_EXPORT___;
