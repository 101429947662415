import { noop } from '@hyperclap/utils';
import React, { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IUser } from '@typings';
import { StreamerSelectDefaultValue } from '@views';

interface StreamerSelectParams {
    debounceTime?: number;
    defaultValue?: StreamerSelectDefaultValue;
    invalidSymbols?: RegExp;
    maxLength?: number;
    readonly?: boolean;
    onChanged?: (value: string) => void;
    onSelect?: (streamer?: IUser) => void;
}

let timer: NodeJS.Timeout;

export const useStreamerSelect = (params: StreamerSelectParams) => {
    const defaultOnChange = (newValue: string) => {
        if (newValue.length > 0) {
            search(newValue);
        } else {
            setSearchResults([]);
        }
    };

    const {
        debounceTime = 0,
        defaultValue,
        invalidSymbols,
        maxLength = 0,
        readonly,
        onChanged = defaultOnChange,
        onSelect = noop,
    } = params;

    const [defaultInitialized, setDefaultInitialized] = useState(false);
    const [value, setValue] = useState<string>('');
    const [searchResults, setSearchResults] = useState<IUser[]>();
    const [selectedStreamer, setSelectedStreamer] = useState<IUser>();
    const [isImagesPreloaded, setIsImagesPreloaded] = useState(true);
    const [errorUrls, setErrorUrls] = useState<string[]>();

    const {
        users: {
            useLazyFindStreamerQuery,
        },
    } = useApi();

    const [
        findStreamerRequest,
        {
            data: streamers,
            isFetching,
            isLoading,
        },
    ] = useLazyFindStreamerQuery();

    const search = (newValue: string) => {
        findStreamerRequest(newValue);
    };

    const reset = () => {
        onChanged('');
        setValue('');
        setSelectedStreamer(undefined);
        onSelect();
    };

    const set = (val: string) => {
        onChanged(val);
        setValue(val);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (readonly) return;

        const inputElementValue = event.target.value;

        if (maxLength && inputElementValue && inputElementValue.length > maxLength) return;

        let newValue: string;

        if (invalidSymbols) {
            newValue = inputElementValue.replace(invalidSymbols, '');
        } else {
            newValue = inputElementValue;
        }

        setValue(newValue);

        if (debounceTime > 0) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                onChanged(newValue);
            }, debounceTime);
        } else {
            onChanged(newValue);
        }
    };

    const preloadImages = async () => {
        if (streamers) {
            const results = streamers.map((res) => {
                if (res.channel.avatarUrl && res.channel.avatarUrl.length > 0) {
                    return new Promise((resolve) => {
                        const loadImg = new Image();
                        loadImg.src = res.channel.avatarUrl || '';
                        loadImg.onload = () => resolve([res.channel.avatarUrl, false]);
                        loadImg.onerror = () => resolve([res.channel.avatarUrl, true]);
                    });
                }
            });

            const states = await Promise.all(results);
            const errors = states.map((res: unknown) => {
                if (res) {
                    const [url, isError] = res as Array<string | boolean>; // TODO: very bad typecasting
                    if (isError) return url as string;
                }
            });

            if (errors) {
                setErrorUrls(errors as string[]);
            }

            setIsImagesPreloaded(true);
        }
    };

    const isLoadInProgress = isLoading || isFetching || !isImagesPreloaded;

    useEffect(() => {
        if (!isFetching && (!streamers || streamers.length === 0)) {
            setSearchResults([]);
            setIsImagesPreloaded(true);
        } else if (!isFetching && streamers) {
            setIsImagesPreloaded(false);
            void preloadImages();
        }
    }, [streamers, isFetching]);

    useEffect(() => {
        if (isImagesPreloaded && streamers) {
            setSearchResults(streamers.map((res) => ({ ...res, id: res._id })));
        }
    }, [isImagesPreloaded]);

    useEffect(() => {
        if (defaultValue && !defaultInitialized) {
            const defaultSelectedStreamer = {
                _id: defaultValue.id,
                id: defaultValue.id,
                channel: {
                    avatarUrl: defaultValue.avatarUrl,
                    name: defaultValue.name,
                },
            } as unknown as IUser; // TODO: very bad typecasting should be replaced
            setSelectedStreamer(defaultSelectedStreamer);
            setDefaultInitialized(true);
        } else if (!defaultInitialized) {
            setSelectedStreamer(undefined);
        }
    }, [defaultValue]);

    return {
        searchResults,
        selectedStreamer,
        isLoadInProgress,
        errorUrls,
        value,
        set,
        reset,
        onChange,
        setSelectedStreamer,
        setIsImagesPreloaded,
    };
};
