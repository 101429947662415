// eslint-disable-next-line import/no-unresolved
import config from 'config';
import { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationType } from '@contexts';
import { useApi, useNotifications } from '@hooks';
import { IVoiceDescriptor } from '@typings';

export const TEST_PHRASE = 'Привет! У тебя классный стрим. Люблю тебя!';

export const useVoices = () => {
    const {
        voices: {
            useLoadVoiceCategoriesQuery,
        },
        users: {
            useUpdateUserVoiceMutation,
        },
    } = useApi();

    const { notify } = useNotifications();
    const { t } = useTranslation();

    const { data: voicesCategories, isFetching: isVoicesFetching } = useLoadVoiceCategoriesQuery();
    const [updateUserVoice] = useUpdateUserVoiceMutation();

    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [voicesInCategory, setVoicesInCategory] = useState<IVoiceDescriptor[]>([]);
    const [playedVoiceId, setPlayedVoiceId] = useState<string>('');

    const stopVoicePlaying = (player: RefObject<HTMLAudioElement>) => {
        if (playedVoiceId && player?.current) {
            player.current.pause();
            player.current.src = '';
            player.current.onended = null;
            player.current.onerror = null;
            setPlayedVoiceId('');
        }
    };

    const playVoice = (voice: IVoiceDescriptor, phrase: string, player: RefObject<HTMLAudioElement>) => {
        const ttsProxy = config?.hosts?.ttsProxyApiHost;

        if (playedVoiceId && player?.current) {
            player.current.pause();
            player.current.src = '';
            player.current.onended = null;
            player.current.onerror = null;
            setPlayedVoiceId('');
        }

        if (!ttsProxy) {
            notify(t('notifications.no-tts-proxy'), NotificationType.ERROR);

            return;
        }

        if (player?.current) {
            player.current.src = `${
                ttsProxy
            }/tts/convert?providerName=${
                voice.provider
            }&voiceId=${
                voice.voice
            }&text=${
                phrase
            }&outputFormat=ogg`;

            player.current.onended = () => {
                if (player.current) {
                    player.current.onended = null;
                    setPlayedVoiceId('');
                }
            };

            player.current.onerror = () => {
                if (player.current) {
                    player.current.onended = null;
                    setPlayedVoiceId('');
                }
            };

            setPlayedVoiceId(voice.id);
            void player.current.play();
        }
    };

    useEffect(() => {
        if (voicesCategories?.length) {
            setSelectedCategory(voicesCategories[0].id);
        }
    }, [voicesCategories]);

    useEffect(() => {
        if (selectedCategory) {
            const voices = voicesCategories?.find((category) => category.id === selectedCategory)?.voices;
            setVoicesInCategory(voices ?? []);
        }
    }, [selectedCategory]);

    return {
        TEST_PHRASE,

        playedVoiceId,
        selectedCategory,
        isVoicesFetching,
        voicesCategories,
        voicesInCategory,
        playVoice,
        stopVoicePlaying,
        setSelectedCategory,
        updateUserVoice,
    };
};
