import { useMatch } from 'react-router-dom';

import { useCurrentUser } from '@hooks';

import { useCatalogue } from './Catalogue';
import { useChannel } from './Channel';
import { useDashboard } from './Dashboard';
import { useFavorites } from './Favorites';
import { useMine } from './Mine';
import { useSearch } from './Search';
import { useSupporters } from './Supporters';

export const usePersonalArea = () => {
    const { currentUser } = useCurrentUser();

    return {
        catalogue: useCatalogue({ active: !!useMatch('/stickers') && !!currentUser }),
        channel: useChannel({ active: !!useMatch('/stickers/channel') && !!currentUser }),
        dashboard: useDashboard({ active: !!useMatch('/dashboard') && !!currentUser, currentUser }),
        favorites: useFavorites({ active: !!useMatch('/stickers/favorites') && !!currentUser }),
        mine: useMine({ active: !!useMatch('/stickers/mine') && !!currentUser }),
        search: useSearch({ active: !!useMatch('/stickers/search') }),
        supporters: useSupporters({ active: !!useMatch('/supporters') && !!currentUser }),
    };
};
