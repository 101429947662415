import { Button, Column, EComponentColor, Loader } from '@hyperclap/ui';
import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IconSmileSadSolid } from '@assets/images/svg';
import { STICKER_SEARCH_QUERY_NAME } from '@common';
import { TApp, useAppActions, useElementOnScreen } from '@hooks';
import { useAppSelector } from '@hooks/store/AppSelector';
import { ISticker, IStickerCustomSettings } from '@typings';
import { Empty, EStickerPopupControlsMode, StickersGrid, useStickerPopup } from '@views';

import s from './Desktop.scss';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const [searchParams] = useSearchParams();
    const query = searchParams.get(STICKER_SEARCH_QUERY_NAME) ?? '';

    const {
        navigation: {
            goToStickerCreation,
        },
        personalArea: {
            search: {
                isPageLoadingAllowed,
                isStickersFetching,
                searchQuery,
                stickers,
                isEndReached,
                updateLoadedSticker,
                loadNextPage,
                changeSearchQuery,
            },
        },
        stickers: {
            switchStickerFavoriteState,
            switchStickerInChannelState,
            changeStickerCustomSettings,
            deleteSticker,
        },
        translation: {
            t,
        },
    } = props;
    const stickersRef = useRef<ISticker[]>([]);
    const loadNextPageRef = useRef<() => Promise<void>>();

    if (query !== searchQuery) {
        changeSearchQuery(query);
    }

    const playSoundOnStickerHover = useAppSelector((state) => state.app.playSoundOnStickerHover);
    const { switchPlaySoundOnStickerHover } = useAppActions();

    const {
        elementRef: watchdogRef,
        isOnScreen: needToLoadPage,
    } = useElementOnScreen<HTMLDivElement>({ threshold: 0 });

    const updateStickerFavoriteState = async (sticker: ISticker) => {
        return await switchStickerFavoriteState(sticker, updateLoadedSticker);
    };

    const updateStickerInChannelState = async (sticker: ISticker) => {
        return await switchStickerInChannelState(sticker, updateLoadedSticker);
    };

    const updateStickerCustomSettings = async (sticker: ISticker, newSettings: IStickerCustomSettings) => {
        return await changeStickerCustomSettings(sticker, newSettings, updateLoadedSticker);
    };

    const goToNextSticker = (currentSticker?: ISticker) => {
        const items = stickersRef.current;
        const index = items.findLastIndex((item) => item.id === currentSticker?.id);
        const nextSticker = items[index + 1];

        // console.log(`${index + 1} / ${items.length}`);

        if (nextSticker) {
            setStickerPopupItem(nextSticker);
        }

        if (index === items.length - 2 && !isEndReached) {
            void loadNextPageRef.current?.();
        }
    };

    const goToPrevSticker = (currentSticker?: ISticker) => {
        const items = stickersRef.current;
        const index = items.findIndex((item) => item.id === currentSticker?.id);
        const prevSticker = items[index - 1];

        if (prevSticker) {
            setStickerPopupItem(prevSticker);
        }
    };

    const {
        showStickerPopup,
        setStickerPopupItem,
    } = useStickerPopup({
        ...props,
        updateCustomSettings: updateStickerCustomSettings,
        updateFavoriteState: updateStickerFavoriteState,
        updateInChannelState: updateStickerInChannelState,
        deleteSticker: deleteSticker,
        controlsMode: EStickerPopupControlsMode.STREAMER,
        goToNextSticker,
        goToPrevSticker,
    });

    const onStickerClick = (sticker: ISticker) => {
        setStickerPopupItem(sticker);
        showStickerPopup();
    };

    useEffect(() => {
        if (needToLoadPage) {
            void loadNextPage();
        }
    }, [needToLoadPage]);

    useEffect(() => {
        stickersRef.current = stickers;
    }, [stickers]);

    useEffect(() => {
        loadNextPageRef.current = loadNextPage;
    }, [loadNextPage]);

    return (
        <Column margin={{ top: 23 }}>
            { stickers.length > 0
                ? <StickersGrid
                    stickers={stickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={onStickerClick}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
                : isStickersFetching
                    ? <Loader />
                    : <Empty
                        padding={{ top: 70 }}
                        icon={<IconSmileSadSolid/>}
                        iconSize={50}
                        text={t('personal-area.search.noStickers')}
                        content={
                            <Column padding={{ top: 20 }}>
                                <Button
                                    caption={t('common.create')}
                                    color={EComponentColor.ACCENT}
                                    width={160}
                                    onClick={goToStickerCreation}
                                />
                            </Column>
                        }
                    />
            }
            {isPageLoadingAllowed &&
                <div className={s.nextPageWatchdog}>
                    <div className={s.nextPageWatchdogMarker} ref={watchdogRef}/>
                </div>
            }
        </Column>
    );
};
